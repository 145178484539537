import axios from 'axios'
const instance = axios.create({
  // baseURL: 'https://some-domain.com/api/',
  timeout: 5000,
  // headers: {'X-Custom-Header': 'foobar'}
});

const startReg = /^\/\//; //匹配双斜线开头
const httpReg = /^(http||https):\/\/([\w.]+\/?)\S*/; //匹配https，http协议开头的url

instance.interceptors.response.use(function (response) {
  let {
    config,
    data
  } = response;
  if (data && (data.code !== 0 && data.code !== 200 && data.code !== "A00000")) {
    return Promise.reject(data);
  }
  return data
}, function (err) {
  return Promise.reject(err);
})
instance.interceptors.request.use(function (config) {

  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});
export default instance;