const REGEX_1 = /&/g
const REGEX_2 = /</g
const REGEX_3 = />/g
const REGEX_4 = / /g
const REGEX_5 = /'/g
const REGEX_6 = /"/g

const REGEX_D_1 = /&amp;/g
const REGEX_D_2 = /&lt;/g
const REGEX_D_3 = /&gt;/g
const REGEX_D_4 = /&nbsp;/g
const REGEX_D_5 = /&#39;/g
const REGEX_D_6 = /&quot;/g

const LOAD_CONFIG = {
  js: {
    tag: 'script',
    urlAttr: 'src',
    attrs: {
      async: true,
    },
  },
  css: {
    tag: 'link',
    urlAttr: 'href',
    attrs: {
      type: 'text/css',
      rel: 'stylesheet',
    },
  },
}
function isWechat() {
  return /micromessenger/i.test(navigator.userAgent);
}

export function showInScreen(el, options = {}) {
  const rect = el.getBoundingClientRect()
  let screenHight = document.documentElement.clientHeight || document.body.clientHeight
  // if (el._interId <= 7) {
  //   console.log(el, 'el')
  //   console.log(screenHight, 'screenHight')
  //   console.log(rect.bottom, 'rect.bottom')
  //   console.log(rect.top, 'rect.top')
  // }
  if(isWechat()){    
    screenHight = screenHight-50;   //微信内的clientHeight包含标题栏
  }
  return !(rect.bottom <= 0 || rect.top >= screenHight)
}

export function htmlEncode(html) {
  let s = ''
  if (!html.length) return ''
  s = html.replace(REGEX_1, '&amp;')
  s = s.replace(REGEX_2, '&lt;')
  s = s.replace(REGEX_3, '&gt;')
  s = s.replace(REGEX_4, '&nbsp;')
  s = s.replace(REGEX_5, '&#39;')
  s = s.replace(REGEX_6, '&quot;')
  return s
}

export function htmlDecode(html) {
  var s = ''
  if (!html.length) return ''
  s = html.replace(REGEX_D_1, '&')
  s = s.replace(REGEX_D_2, '<')
  s = s.replace(REGEX_D_3, '>')
  s = s.replace(REGEX_D_4, ' ')
  s = s.replace(REGEX_D_5, '\'')
  s = s.replace(REGEX_D_6, '"')
  return s
}

export function isRootElement(element) {
  return element === document.body ||
    element === document.documentElement ||
    element === document
}

export function load(url = '', opt = {}) {
  const cfg = LOAD_CONFIG[opt.type || 'js']
  const isHasScript = document.querySelector(`${cfg.tag}[${cfg.urlAttr}="${url}"]`)
  if (isHasScript || !url) return Promise.resolve()

  const target = document.getElementsByTagName('script')[0] || document.head
  let script = document.createElement(cfg.tag)
  script[cfg.urlAttr] = url
  for (let attrName in cfg.attrs) {
    script[attrName] = cfg.attrs[attrName]
  }
  target.parentNode.insertBefore(script, target)
  return new Promise((resolve, reject) => {
    script.onload = () => {
      script = null
      resolve()
    }
    script.onerror = (error) => {
      cleanup()
      reject(error)
    }
  })

  function cleanup() {
    if (script.parentNode) script.parentNode.removeChild(script)
    script = null
  }
}

export function parentElement(element, tag) {
  let parent = element
  while (parent && parent.tagName !== tag) {
    parent = parent.parentNode
  }
  return parent
}

export function indexElement(element) {
  const parent = element.parentNode
  return Array.from(parent.children).indexOf(element)
}

export function scrollToElement(element, adjust = 0) {
  if (!element) return
  const top = element.offsetTop - adjust
  scrollTo(top)
}

export function scrollTo(top) {
  document.documentElement.scrollTop = top
  document.body.scrollTop = top
}

export function getResolution() {
  return {
    width: screen.width * window.devicePixelRatio,
    height: screen.height * window.devicePixelRatio,
  }
}
