<template>
    <div class="side-bar">
        <div class="channel-sidebar">
            <div v-for="item in channelList" :key="item.channelId" class="channel" @click="chooseChannel(item)">
                <img :src="item.icon" class="channel-icon"/>
                <span class="channel-title">{{item.name}}
                    <span v-if="channelId==item.channelId" class="active-box">
                        <span class="active"></span>
                    </span>
                </span>
            </div>
        </div>
         <div class="line"></div>
         <div class="policy">
             <div class="policy-item" @click="goService"><a href="https://www.iqiyi.com/user/register/protocol.html" class="seo-a" @click.prevent>服务协议</a></div>
             <div class="policy-item" @click="goPolicy"><a href="https://static.iqiyi.com/suikem/skprivateh5/privacy.html" class="seo-a" @click.prevent>隐私政策</a></div>
             <div class="policy-item" @click="goLicense"><a href="https://static-d.iqiyi.com/lequ/20210705/33b8a6ae250d4f47afcf5f95b3f67f7a.pdf" class="seo-a" @click.prevent>信息网络传播视听节目许可证</a></div>
             <div class="policy-item" @click="goBussinessLicense"><a href="https://static-s.iqiyi.com/lequ/20240320/0e1673f4210048d484a4b551d95df297.pdf" class="seo-a" @click.prevent>网络文化经营许可证</a></div>
             <div class="policy-item" @click="goBusiness"><a href="http://static-s.iqiyi.com/ext/skimg/%E8%90%A5%E4%B8%9A%E6%89%A7%E7%85%A7.pdf" class="seo-a" @click.prevent>营业执照</a></div>
             <div class="policy-item" @click="goRecord"><a href="https://beian.miit.gov.cn/" class="seo-a" @click.prevent>网站备案号：沪B2-20060072</a></div>

             <div class="policy-item" >违法和不良信息举报电话：400-923-7171</div>
             <div class="policy-item" @click="goInternetReport"><a href="https://www.12377.cn/" class="seo-a" @click.prevent>中国互联网违法和不良信息举报中心</a></div>
             <div class="policy-item" @click="goShanghaiReport"><a href="https://www.shjbzx.cn/" class="seo-a" @click.prevent>上海市举报中心</a></div>
             <div class="policy-item" @click="goReportCall"><a href="https://www.shdf.gov.cn/" class="seo-a" @click.prevent>“扫黄打非”办公室举报电话：12390</a></div>

             <div class="copyright">Copyright © {{ year }} 上海众源网络有限公司 All Rights Reserved </div>
         </div>
    </div>
</template>

<script>
// import axios from "@/common/request.js";
// import PingBack from '@/common/PingBack';
// import Application from '@/common/new-util.js';
    export default {
        props:{
            channelId:{
                type:Number,
                default:-1
            },
            channelList:[]
        },
        data(){
            return {
                // channelList:[],
                rpage:"pcw_home",
                year: new Date().getFullYear()
            }
        },
        created(){
            // this.getChannelList()

        },
        beforeMount(){
            let self=this
            // self.init()
            PingBack.getInstance().sendBlockPingBack('pcw_home','category_tab')
            PingBack.getInstance().sendBlockPingBack('pcw_home','company')
        },
        methods:{
            // init() {
            // let self = this;
            // axios(Application.baseApiUrl + "/api/route/pps/mobile/queryTopTabList")
            //     .then(res => {
            //     self.loading = false;
            //     let { code, data } = res;
            //     if (code == "A00000") {
            //         self.channelList = data.list;
            //     }
            //     })
            //     .catch(() => {
            //     self.loading = false;
            //     });
            // },
            // getChannelList(){
            //     let self=this
            //     Api.get("/api/route/pps/mobile/queryTopTabList").then(res=>{
            //         self.channelList=res.data.list
            //     })
            // },
            chooseChannel(item){
                console.log('hhhh',item.channelId)
                this.$router.push({ name: item.name},onComplete=>{},onAbort=>{})
                PingBack.getInstance().sendClickPingBack(this.rpage,'category_tab','category_'+item.channelId)
            },
            goService(){
                window.open("https://www.iqiyi.com/user/register/protocol.html")
                PingBack.getInstance().sendClickPingBack(this.rpage,'company','protoco')
            },
            goPolicy(){
                window.open("https://static.iqiyi.com/suikem/skprivateh5/privacy.html")
                PingBack.getInstance().sendClickPingBack(this.rpage,'company','policies')
            },
            goLicense(){
                window.open("https://static-d.iqiyi.com/lequ/20210705/33b8a6ae250d4f47afcf5f95b3f67f7a.pdf")
            },
            goBussinessLicense(){
                window.open("https://static-s.iqiyi.com/lequ/20240320/0e1673f4210048d484a4b551d95df297.pdf")
            },
            goBusiness(){
                window.open("http://static-s.iqiyi.com/ext/skimg/%E8%90%A5%E4%B8%9A%E6%89%A7%E7%85%A7.pdf")
            },
            goInternetReport(){
                window.open("https://www.12377.cn/")
            },
            goShanghaiReport(){
                window.open("https://www.shjbzx.cn/")
            },
            goReportCall(){
                window.open("https://www.shdf.gov.cn/")
            },
            goRecord(){
                window.open("https://beian.miit.gov.cn/")
            }

        }
    }
</script>

<style lang="scss" scoped>
.side-bar{
     width:222px;
     border-right: 1px solid #EDEEF0;
     height: 100%;
     padding:0 24px;
     box-sizing: border-box;
     overflow-y: auto;
     float: left;
    .channel-sidebar{
        box-sizing: border-box;
        width:100%;
        padding:24px 0 16px 0;
        min-height: 488px;
        .channel {
            padding-left: 16px;
            height: 22px;
            line-height: 22px;
            margin:16px 0;
            overflow: hidden;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;
            &:hover{
                opacity: 0.8;
            }
            // &:not(:last-of-type){
            //     margin-bottom: 16px;
            // }
            .channel-icon{
                width:20px;
                height: 20px;
                margin-right: 24px;
                vertical-align: middle;
                cursor: pointer;
            }
            .channel-title{
                font-family: PingFangSC-Regular;
                font-size: 14px;
                line-height: 22px;
                color: #1F2229;
                letter-spacing: 0;
                font-weight: 400;
                position: relative;
                width:43px;
                overflow: hidden;
                display: inline-block;
                vertical-align: middle;
                border-radius: 4px;
                cursor: pointer;
                z-index:0;
                .active-box{
                position: absolute;
                width:43px;
                height: 8px;
                z-index: -1;
                top:15px;
                left:-2px;
                border-radius: 4px;
                overflow: hidden;
                .active{
                    position: absolute;
                    width:100%;
                    height: 100%;
                    display: inline-block;
                    background: #FFDEE0;
                filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#FFDEE0, endColorstr=#EEE0FF);/*IE<9>*/
-ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=1, startColorstr=#FFDEE0, endColorstr=#EEE0FF)";/*IE8+*/
                background-image:linear-gradient(269deg, #FFF2CC 0%, #FFDEE0 50%, #EEE0FF 100%);
                }
                }
            }
        }

    }
    .line{
        // width:174px;
        height: 1px;
        background: #EDEEF0;
        // margin:0 24px;
     }
     .policy{
        padding:24px 0  26px 0px;
        .policy-item{
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #4C5059;
            letter-spacing: 0;
            line-height: 18px;
            font-weight: 400;
            cursor: pointer;
            margin-bottom:8px;
            &:hover{
                opacity: 0.8;
            }
        }
        .copyright{
            font-family: PingFangSC-Regular;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
            font-weight: 400;
            color: #999DA6;
        }
     }
}
</style>
