<template>
    <div class="container">
         <TopBar></TopBar>
         <div class="content">
             <ChannelBar :channelId="activeId" :channelList="channelList"></ChannelBar>
             <router-view :key="key"/>
         </div>
    </div>
</template>

<script>
    import ChannelBar from "./components/channelbar";
    import TopBar from "./components/topbar";
    import Channels from "../channel"
    export default {
        asyncData({ store, route }) {
            // console.log('获取channel')
            return store.dispatch("FETCH_CHANNEL");
        },
        data(){
            return {
                activeId:-1,
                rpage:""
            }
        },
        components:{
            TopBar,
            ChannelBar
        },
        beforeMount(){
            window.PingBack = require("@/common/PingBack");
            // window.WXUtil2 = require("@/common/WXUtil2");
            // window.WXTOOL = require("@/common/wx-open-launch");
            window.Utils = require("@/common/Utils");
            window.BridgeUtilPPS = require("@/common/BridgeUtilPPS");
        },
        computed: {
            channelList() {
            return this.$store.state.channels || [];
            },
            key() {
                return this.$route.fullPath + new Date();
            }
        },
        watch: {
            $route(to,from) {
                let {rpage } = to.matched[1].props.default();
                this.rpage=rpage
                this.activeId = Channels[to.name].id;
            }
        },
        created(){
            let name=this.$route.name
            this.activeId=Channels[name].id
            this.rpage=this.$route.matched[1].props.default().rpage
            console.log('哈哈rpage',this.rpage);
        },
        methods:{
        }
    }
</script>

<style lang="scss" scoped>
$topbarHeight:56px;
.container{
    height: 100%;
    font-size: 12px;
    background:#F3F4F6 ;
    .content{
        width:100%;
        clear: both;
        // display: flex;
        height: calc(100% - #{$topbarHeight});
        background: #ffffff;
        overflow: hidden;
        // align-items: stretch;
    }
}
     @media (min-width: 1968px){
        .content{
            width:1968px!important;
            margin:0 auto;
        }
    }
</style>