// store.js
import Vue from 'vue'
import Vuex from 'vuex'
import axios from "@/common/request.js"
import Application from "@/common/new-util.js"
Vue.use(Vuex)

// 假定我们有一个可以返回 Promise 的
// 通用 API（请忽略此 API 具体实现细节）
// import { fetchItem } from './api'
function sendPingback(){
  let url = `https://toutiao.iqiyi.com/pps/h5/v1/qos/fe.gif?debug=1&app_key=h5&hx_pcw_browser_error=1`
  axios.get(url)
}
export function createStore () {
  return new Vuex.Store({
    state: {
      channels: [],
      dataList:[],
      bannerLists:[],
      from:0,
      pageSize:20,
    },
    getters:{
      getCurrentChannel(state){
        console.log(state.route.name,'state.route.name')
        return state.route.name;
      }
    },
    actions: {
      FETCH_CHANNEL ({ commit }) {
        // `store.dispatch()` 会返回 Promise，
        // 以便我们能够知道数据在何时更新
        console.log('baseUrl',Application.baseApiUrl)
        return axios(Application.baseApiUrl+"/api/route/pps/mobile/queryTopTabList").then(res => {
          console.log('频道')
          if(res.data&&res.data.list){
            let channels = res.data.list;
            commit('setChannel',channels)
          }
          else{
            sendPingback()
          }
        }).catch(err=>{
          sendPingback()
          console.log('err',err)
        })
      },
      FETCH_VIDEO_LIST({ commit,state },params) {
        // console.log(state,'state',params)
        console.log('-------FETCH_VIDEO_LIST-------')
        state.channelId=params.channelId
        // `store.dispatch()` 会返回 Promise，
        // 以便我们能够知道数据在何时更新
        return axios({url:Application.baseApiUrl+"/api/route/pps/mobile/queryVideoList",params}).then(res => {
          if(res&&res.data){
            commit('setInitDataList',res.data)
          }
          else{
            sendPingback()
          }
          return res
        }).catch(err=>{
          sendPingback()
          console.log(err)
        })
      },
      FETCH_NEXT_PAGE({ commit,state },params) {
        console.log('-------FETCH_NEXT_PAGE-------')
        // `store.dispatch()` 会返回 Promise，
        // 以便我们能够知道数据在何时更新
        return axios({url:Application.baseApiUrl+"/api/route/pps/mobile/queryVideoList",params}).then(res => {
          if(res&&res.data){
            commit('setDataList',res.data)
          }
          else{
            sendPingback()
          }
          return res;
        }).catch(err=>{
          sendPingback()
          console.log('err',err)
          return err
        })
      },
      
    },
    mutations: {
      setChannel (state, channels) {
        state.channels = channels;
      },
      setDataList(state, data){
        state.dataList = state.dataList.concat(data.list);
        state.from=data.from
      },
      setInitDataList(state, data){
        state.dataList = data.list;
        state.bannerLists = data.bannerList||[];
        // console.log('banner',state.bannerLists)
        state.dataList=state.bannerLists.concat(state.dataList)
        state.from = data.from;
      },
    }
  })
}