const Application = require('./Application');

let Clipboard=function (h,g,a) {
  return {
    fakeElem: null, text: h, action: g, selectedText: null, dd: document, ww: window, start: function () {
      this.text && (parent.window.location.href != window.location.href && (this.ww = top.window, this.dd = top.document), this.selectFake())
    }, selectFake: function () {
      var a = "rtl" == this.dd.documentElement.getAttribute("dir");
      this.removeFake();
      this.fakeElem = this.dd.createElement("textarea");
      this.fakeElem.style.fontSize = "12pt";
      this.fakeElem.style.border = "0";
      this.fakeElem.style.padding = "0";
      this.fakeElem.style.margin = "0";
      this.fakeElem.style.position = "absolute";
      a ? this.fakeElem.style.right = "-9999px" : this.fakeElem.style.left = "-9999px";
      this.fakeElem.style.top = (this.ww.pageYOffset || this.dd.documentElement.scrollTop) + "px";
      this.fakeElem.setAttribute("readonly", "");
      this.fakeElem.value = this.text;
      this.dd.body.appendChild(this.fakeElem);
      this.selectedText = this.selectText(this.fakeElem);
      this.copyText()
    }, removeFake: function () {
      this.fakeElem && (this.dd.body.removeChild(this.fakeElem), this.fakeElem = null)
    }, copyText: function () {
      var b = void 0;
      try {
        b = this.dd.execCommand(this.action)
      } catch (c) {
        b = !1
      }
      this.removeFake();
      a.call(this, b)
    }, selectText: function (a) {
      if ("SELECT" === a.nodeName) a.focus(), a = a.value; else if ("INPUT" === a.nodeName || "TEXTAREA" === a.nodeName) {
        var b = a.hasAttribute("readonly");
        b || a.setAttribute("readonly", "");
        a.select();
        a.setSelectionRange(0, a.value.length);
        b || a.removeAttribute("readonly");
        a = a.value
      } else {
        a.hasAttribute("contenteditable") && a.focus();
        b = this.ww.getSelection();
        var e = this.dd.createRange();
        e.selectNodeContents(a);
        b.removeAllRanges();
        b.addRange(e);
        a = b.toString()
      }
      return a
    }
  };
}
//ios 版本>=10 chrome内核版本>=44
Clipboard.isValid=(Application.os.ios&&Number((Application.os.version.split('.')[0])>=10))||(Application.os.android&&Number(Application.browser.version.split('.')[0])>=44);

module.exports = Clipboard;
