<template>
    <div class="longVideo" id="longVideo" ref="longVideo">
        <template v-if="videoLists.length" v-show-pingback="{
            rpage: 'pcw_home_'+Channels[name].eName,
            block:'pcw_feed',
            t:21
            }">
            <div class="video-content" v-for="(item,index) in videoLists" :key="'video'+index" @click="goVideo(item)">
                <a :href="item.playUrl" class="seo-a" @click.prevent>
                <div class="image-box">
                    <img :src="item.image" class="video-image"/>
                    <span class="episode" v-if="channelId==1">{{item.snsScore}}</span>
                    <template v-else-if="channelId==6">
                    <span class="episode">{{item.seriesList[item.seriesList.length-1].publishDate.replace(/(.{4})(.{2})/g, "$1-$2-")}}期</span>
                    </template>
                    <template v-else>
                    <span class="episode" v-if="item.finish">{{item.seriesTotal}}集全</span>
                     <span class="episode" v-else>更新至{{item.lastestSeries}}集</span>
                    </template>
                </div>
                <div class="video-msg">
                        <div class="video-title">{{item.title}}</div>
                        <div class="introduce-msg">{{item.promptDescription}}</div>
                </div>
                </a>
            </div>
        </template>
        <div class="tip-box">
            <div class="loading-box">
            <div ref="lottie" class="sk-loading" v-show="loading"></div>
            </div>
            <div v-if="!loading&&noData" class="noData">暂无更多数据</div>
        </div>
    </div>
</template>

<script>
// import axios from "@/common/request.js";
// import PingBack from "@/common/PingBack"
import Channels from "../channel"
import lottie from 'lottie-web';
import animationData from "../imgs/loading.json";
    export default {
        props: {
            rpage: {
            type: String,
            default: ""
            }
        },
        data(){
            return {
                pageSize:30,
                loading:false,
                noData:false,
                scrollLoading:false,
                concatBanner:false,
                timeoutCount:0
            }
        },
        computed: {
            videoLists(){
                return this.$store.state.dataList || [];
            },
            from(){
                return this.$store.state.from||0
            },
            channelId(){
                return this.$store.state.channelId||-1
            }
        },
        beforeMount(){
            let name=this.$route.name
            this.channelId=Channels[name].id
            let _uaDetect = navigator.userAgent;
            this.platform = _uaDetect.match(/(Android);?[\s\/]+([\d.]+)?/)? "ANDROID_PPS": "IOS_PPS";
            // this.getVideoList()
            console.log('rpage',this.rpage)
            PingBack.getInstance().sendLoadPingBack(this.rpage);
            PingBack.getInstance().sendBlockPingBack('pcw_home_'+Channels[name].eName,'pcw_feed')
        },
        mounted(){
             this.$nextTick(()=>{
                lottie.loadAnimation({
                    container: this.$refs.lottie, // the dom element that will contain the animation
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    animationData: animationData // the path to the animation json
                });
                window.addEventListener("scroll", this.handleScroll,true);
             })
        },
        destroyed() {
            console.log("destroyed销毁");
            window.removeEventListener("scroll", this.handleScroll);
            this.bscroll = null;
        },
        methods:{
            handleScroll() {
                // let targetElement=document.querySelector('#longVideo')
                let targetElement=this.$refs['longVideo']
                if(targetElement)
                {   
                    let scrollHeight =targetElement.scrollHeight;
                    let scrollTop =targetElement.scrollTop;
                    let clientHeight = targetElement.clientHeight;
                    if (scrollTop + clientHeight >= scrollHeight - 120 && !this.scrollLoading) {
                        this.scrollLoading = true;
                        // this.from += this.pageSize;
                        this.getVideoList(this.channelI);
                        console.log("已到底");
                    }
                }
            },
            getVideoList(){
                let self=this
                self.loading=true
                  this.$store
        .dispatch("FETCH_NEXT_PAGE", {
          channelId: this.channelId,
          platform: self.platform||'IOS_PPS',
          from: this.from+this.pageSize,
          size: this.pageSize,
          source:"new_request"
        }).then(data=>{
            if(data.data)
            {
                data=data.data
                self.timeoutCount=0
                 self.scrollLoading=false
                 self.loading=false
                //  let data=res.data
                 self.from = data.from;
                //如果bannerList有值，则拼到视频列表里面，且只拼一次
                // if(data.bannerList&&data.bannerList.length>0&&!self.concatBanner){
                //     self.concatBanner=true
                //     self.videoLists=self.videoLists.concat(data.bannerList)
                // }
                 if(data.list)
                {
                    //是否还有数据
                    if (!data.nextHaveData){
                        self.noData=true
                        window.removeEventListener("scroll", this.handleScroll);
                    }
                    self.videoLists=self.videoLists.concat(data.list)
                }
                }else if (data.code == "E00001") {
                    console.log('超时')
                    //超时出现3次就停止请求，直接返回无数据
                    if(self.timeoutCount>2){
                        self.loading=false
                        self.noData=true
                        window.removeEventListener("scroll", this.handleScroll);
                    }
                    else{
                        self.timeoutCount++;
                        self.getVideoList();
                    }

                }
                else{
                    self.loading=false
                    self.noData=true
                    window.removeEventListener("scroll", this.handleScroll);
                }
                }).catch(err => {
                if (err.code == "E00001") {
                    //超时出现3次就停止请求，直接返回无数据
                    if(self.timeoutCount>2){
                        self.loading=false
                        self.noData=true
                        window.removeEventListener("scroll", this.handleScroll);
                    }
                    else{
                        self.timeoutCount++;
                        self.getVideoList();
                    }

                }
                else{
                    self.loading=false
                    self.noData=true
                    window.removeEventListener("scroll", this.handleScroll);
                }
                console.log("err", err);
                });
            },
            goVideo(video){
                let name=this.$route.name
                PingBack.getInstance().sendClickPingBack('pcw_home_'+Channels[name].eName,'pcw_feed','conten',{'contid':video.id})
                window.open(video.playUrl)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .longVideo{
        padding:24px;
        float: left;
        width:calc(100% - 270px);
        height: 100%;
        overflow-y: auto;
        // display: flex;
        // flex-flow: wrap;
        .video-content{
            // width:32%;
            float:left;
             &:hover{
                opacity: 0.8;
            }
            .seo-a{
                display: block;
            }
            // &:nth-of-type(3n-1){
            //     margin:0 2%;
            // }
            .image-box{
                width:100%;
                height: 0;
                padding-bottom: 56.25%;
                background:#eee;
                position: relative;
                overflow: hidden;
                .video-image{
                    width:100%;
                    height: auto;
                }
                .episode{
                    position: absolute;
                    right: 10px;
                    bottom:10px;
                    color:#FFFFFF;
                    font-family: PingFangSC-Regular;
                    font-size: 14px;
                }
            }
            .video-msg{
                padding:12px 0 24px 0 ;
                .video-title{
                    opacity: 0.8;
                    font-family: PingFangSC-Semibold;
                    font-size: 14px;
                    color: #1F2229;
                    word-wrap: break-word;
                    letter-spacing: 0;
                    line-height: 22px;
                    font-weight: 600;
                }
                .introduce-msg{
                    width:100%;
                    font-family: PingFangSC-Regular;
                    font-size: 12px;
                    color: #999DA6;
                    letter-spacing: 0;
                    // line-height: 12px;
                    font-weight: 400;
                    margin-top: 12px;
                    white-space:nowrap;
                    overflow:hidden;
                    text-overflow:ellipsis;
                }
            }
        }
        .tip-box{
            width:100%;
            clear: both;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            .loading-box{
                width:100%;
                .sk-loading{
                text-align: center;
                margin:30px auto;
                width:112px;
                position: relative;
                overflow: hidden;
                }
            }
            .noData{
                text-align: center;
                font-size: 14px;
                width:112px;
                margin:20px auto;
            }
        }
    }
    @media(max-width: 1160px){
        .longVideo{
            .video-content{
                width:32%;
                &:nth-of-type(3n-1){
                    margin:0 2%;
                }
                &:nth-of-type(3n-2){
                    clear:both;
                }
            }
        }
    }
     @media (min-width: 1161px) and (max-width: 1967px){
        .longVideo{
            .video-content{
                width:24%;
                margin-right:1.3%;
                &:nth-of-type(4n){
                    margin-right: 0;
                }
                &:nth-of-type(4n-3){
                    clear:both;
                }
            }
        }
    }
    @media (min-width: 1968px){
        .longVideo{
            .video-content{
                width:19%;
                margin-right:1.2%;
                &:nth-of-type(5n){
                    margin-right: 0;
                }
                &:nth-of-type(5n-4){
                    clear:both;
                }
            }
        }
    }
</style>