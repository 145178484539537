// import Application from "./Application";
// import AppCaller from "./AppCaller";
// import Utils from "./Utils";
const Application = require("./Application")
const AppCaller = require("./AppCaller")
const Utils = require("./Utils")

const call = Symbol('call');

const jumpByRegistration = Symbol('jumpByRegistration');

class BridgeUtilPPS {

  static CALL_HANDLER_METHOD = {
    JSBRIDGE_INIT_PAGE: 'JSBRIDGE_INIT_PAGE', //获取页面初始化数据成功
    JSBRIDGE_SHARE: 'JSBRIDGE_SHARE', //分享
    JSBRIDGE_LOGIN_LITE: 'JSBRIDGE_LOGIN_LITE', //新版登录

    JSBRIDGE_PLAY_VOICE: 'JSBRIDGE_PLAY_VOICE', //播放mp3
    JSBRIDGE_STOP_VOICE: 'JSBRIDGE_STOP_VOICE', //停止播放

    JSBRIDGE_CHECKPERMS_NOTIFICATIONINFO: 'JSBRIDGE_CHECKPERMS_NOTIFICATIONINFO', //检查是否开启通知权限
    JSBRIDGE_OPENPERMS_NOTIFICATIONINFO: 'JSBRIDGE_OPENPERMS_NOTIFICATIONINFO', //开启通知权限


    JSBRIDGE_CHECKPERMS_ADDRESSBOOK: 'JSBRIDGE_CHECKPERMS_ADDRESSBOOK', //检查是否开启使用通讯录权限
    JSBRIDGE_OPENPERMS_ADDRESSBOOK: 'JSBRIDGE_OPENPERMS_ADDRESSBOOK', //开启通讯录权限
    JSBRIDGE_GET_ADDRESSBOOK: 'JSBRIDGE_GET_ADDRESSBOOK', //读取通讯录数据


    JSBRIDGE_CHECKPERMS_ALBUM: 'JSBRIDGE_CHECKPERMS_ALBUM', //检查是否开启使用相册权限
    JSBRIDGE_OPENPERMS_ALBUM: 'JSBRIDGE_OPENPERMS_ALBUM', //开启使用相册权限
    JSBRIDGE_STORE_ALBUM: 'JSBRIDGE_STORE_ALBUM', //保存至相册

    JSBRIDGE_CHECKPERMS_CALENDAR: 'JSBRIDGE_CHECKPERMS_CALENDAR', //是否开启使用日历权限
    JSBRIDGE_OPENPERMS_CALENDAR: 'JSBRIDGE_OPENPERMS_CALENDAR', //开启使用日历权限
    JSBRIDGE_ADD_CALENDAR: 'JSBRIDGE_ADD_CALENDAR', //添加日历

    JSBRIDGE_VideoRedPacket: 'JSBRIDGE_VideoRedPacket', //视频开关

    JSBRIDGE_INSTALL_SHORTCUT: 'JSBRIDGE_INSTALL_SHORTCUT', //创建桌面快捷方式**(适用于安卓系统>=8.0)
    JSBRIDGE_OPENPERMS_INSTALL_SHORTCUT: 'JSBRIDGE_OPENPERMS_INSTALL_SHORTCUT', //开启使用桌面快捷方式权限**(适用于安卓系统>=8.0)
    JSBRIDGE_PAGE_LIFECYCLE: 'JSBRIDGE_PAGE_LIFECYCLE', //页面重新获得焦点
  };

  /**
   * 频道id
   * wiki: http://wiki.qiyi.domain/pages/viewpage.action?pageId=394233023
   */
  static CHANNEL_ID = {
    FOCUS: 72916, //关注
    RECOMMEND: 8196, //推荐
    FILM_TV: 1000888, //影视

    TV: 1002, //电视剧
    COMEDY: 22, //搞笑
    FILM: 1001, //电影

    GAME: 8, //游戏
    CHILD: 10159, //儿童
    ENTERTAINMENT: 7, //娱乐

    ORIGINAL: 27, //原创
    MUSIC: 5, //音乐
    MATERNAL_BABY: 29, //母婴

    VARIETY_SHOW: 1006, //综艺
    LIFE: 21, //生活
    CARTOON: 1004, //动漫

    NEWS: 25, //资讯
    FASHION: 13, //时尚
    MILITARY: 28, //军事

    CAR: 26, //汽车
    HEALTH: 32, //健康
    technology: 30, //科技

    FINANCE: 24, //财经
    TOURISM: 9, //旅游
    HOT: 72915, //榜单

    SINGER: 222, //唱作人
  }

  /**
   * 公共调用原生方法
   * @param method
   * @param params
   * @param callBack
   */
  static[call](method, params, callBack) {
    if (Application.browser.pps || Application.browser.iqiyiAPP) {
      iqiyi.commonInvoke(method, params, callBack)
    }
  };


  /**
   * 页面初始化
   * @param callBack
   */
  static callHandlerInit(callBack) {
    this[call](BridgeUtilPPS.CALL_HANDLER_METHOD.JSBRIDGE_INIT_PAGE, null, callBack);
  };

  /**
   * 调起分享面板
   * @param callBack
   */
  static callHandlerShare(prams, callBack) {
    this[call](BridgeUtilPPS.CALL_HANDLER_METHOD.JSBRIDGE_SHARE, prams, callBack);
  };

  /**
   * 登录
   * @param callBack
   */
  static callHandlerLogin(callBack) {
    if (Utils.compareVersion(Application.appInfo.appVersion, '9.11.0') >= 0) {
      if (!callBack) {
        callBack = function (res) {
          location.reload();
        }
      } else {
        console.log('有回调函数');
      }
      this[call](BridgeUtilPPS.CALL_HANDLER_METHOD.JSBRIDGE_LOGIN_LITE, {
        "page": "login",
        "login_type": "half"
      }, callBack);
    } else {
      iqiyi.loadNativePage({
        page: 'login'
      });
    }
  };

  /**
   * 关闭当前页面
   */
  static callHandlerClose() {
    iqiyi.closePage();
  }

  /**
   * 关闭当前页面
   */
  static callHandlerHideMenu() {
    iqiyi.hideMenu({
      force: true // 是否强制隐藏(同时隐藏所有 native 设置的菜单)
    });
  }

  /**
   * 检查是否开启日历权限
   */
  static callHandlerCheckPermsCalendar(callBack) {
    iqiyi.commonInvoke(
      // type: 调用的 type
      'JSBRIDGE_CHECKPERMS_CALENDAR',
      // 调用该 type 需要传递的 params
      {},
      // 调用该type 的回调
      function (data) {
        callBack && callBack(data);
      }
    );
  }

  /**
   * 开启日历权限
   */
  static callHandlerOpenPermsCalendar(callBack) {
    iqiyi.commonInvoke(
      // type: 调用的 type
      'JSBRIDGE_OPENPERMS_CALENDAR',
      // 调用该 type 需要传递的 params
      {},
      // 调用该type 的回调
      function (data) {
        callBack && callBack(data);
      }
    );
  }


  /**
   * 检查是否开启通知权限
   */
  static callHandlerCheckPermsNotificationinfo(callBack) {
    iqiyi.commonInvoke(
      // type: 调用的 type
      'JSBRIDGE_CHECKPERMS_NOTIFICATIONINFO',
      // 调用该 type 需要传递的 params
      {},
      // 调用该type 的回调
      function (data) {
        callBack && callBack(data);
      }
    );
  }


  /**
   * 开启通知权限
   */
  static callHandlerOpenPermsNotiicationinfo(callBack) {
    iqiyi.commonInvoke(
      // type: 调用的 type
      'JSBRIDGE_OPENPERMS_NOTIFICATIONINFO',
      // 调用该 type 需要传递的 params
      {},
      // 调用该type 的回调
      function (data) {
        callBack && callBack(data);
      }
    );
  }

  /**
   * 获得会员卡刷新会员卡状态
   */
  static callHandlerUploadUserInfo(callBack) {
    iqiyi.commonInvoke(
      // type: 调用的 type
      'JSBRIDGE_UPLOAD_USER_INFO',
      // 调用该 type 需要传递的 params
      {},
      // 调用该type 的回调
      function (data) {
        callBack && callBack(data);
      }
    );
  }

  /**
   * 开启使用桌面快捷方式权限**(适用于安卓系统>=8.0)
   */
  static callHandlerOpenpermsInstallShortcut(callBack) {
    iqiyi.commonInvoke(
      // type: 调用的 type
      BridgeUtilPPS.CALL_HANDLER_METHOD.JSBRIDGE_OPENPERMS_INSTALL_SHORTCUT,
      // 调用该 type 需要传递的 params
      {},
      // 调用该type 的回调
      function (data) {
        callBack && callBack(data);
      }
    );
  }

  /**
   * 开启使用桌面快捷方式权限**(适用于安卓系统>=8.0)
   */
  static callHandlerLifeCycleOnResume(callBack) {
    iqiyi.commonInvoke(
      // type: 调用的 type
      BridgeUtilPPS.CALL_HANDLER_METHOD.JSBRIDGE_PAGE_LIFECYCLE,
      // 调用该 type 需要传递的 params
      {type: "onResume"},
      // 调用该type 的回调
      function (data) {
        callBack && callBack(data);
      }
    );
  }

  /**
   * 创建桌面快捷方式**(适用于安卓系统>=8.0)
   * 通过尝试创建桌面快捷方式权限回调获取是否开启
   */
  static callHandlerInstallShortcut({icon, name, scheme,},callBack) {
    iqiyi.commonInvoke(
      // type: 调用的 type
      BridgeUtilPPS.CALL_HANDLER_METHOD.JSBRIDGE_INSTALL_SHORTCUT,
      // 调用该 type 需要传递的 params
      {icon, name, scheme,},
      // 调用该type 的回调
      function (data) {
        callBack && callBack(data);
      }
    );
  }

  /**
   * 通过注册制进行页面跳转
   * wiki: http://wiki.qiyi.domain/pages/viewpage.action?pageId=358285808
   */
  static[jumpByRegistration](params, otherParams = {}) {
    let scheme = 'iqiyipps://tv.pps.mobile/register_business/qyclient?ftype=27&pluginParams=' + encodeURIComponent(encodeURIComponent(JSON.stringify(params)));
    console.log(scheme, 'scheme')
    if (Application.browser.pps) {
      location.href = scheme;
    } else {
      //站外唤起打开逻辑下载
      AppCaller.call(scheme, otherParams);
    }
  };

  static createScheme(params) {
    return 'iqiyipps://tv.pps.mobile/register_business/qyclient?ftype=27&pluginParams=' + encodeURIComponent(encodeURIComponent(JSON.stringify(params)));
  }

  static jump2H5Page(url, {
    pingBack = '',
    dl_bkey = ''
  } = {}) {
    let biz_statisticsObj = {};
    dl_bkey ? biz_statisticsObj.dl_bkey = dl_bkey : '';
    let params = {
      "biz_id": "100",
      "biz_plugin": "qiyibase",
      "biz_params": {
        "biz_sub_id": "202",
        "biz_params": "",
        "biz_dynamic_params": "url=" + encodeURIComponent(url),
        "biz_extend_params": "",
        "biz_statistics": Utils.obj2QueryEmpty(biz_statisticsObj)
      }
    };
    this[jumpByRegistration](params);
  }

  /**
   * 跳转到首页 首页tab
   * @param channelId 频道id 默认推荐页频道
   */
  static jump2TabHome(channelId,dl_bkey) {
    var biz_statisticsObj = {};
    if(dl_bkey){
      biz_statisticsObj.dl_bkey = dl_bkey;
    }
    let params = {
      "biz_id": "100",
      "biz_plugin": "qiyibase",
      "biz_params": {
        "biz_sub_id": "112",
        "biz_params": "selectedTab=" + (channelId || BridgeUtilPPS.CHANNEL_ID.RECOMMEND),
        "biz_dynamic_params": "",
        "biz_extend_params": "",
        "biz_statistics": Utils.obj2QueryEmpty(biz_statisticsObj)
      }
    };
    this[jumpByRegistration](params);
  }

  /**
   * 跳转到首页 影视tab
   * @param channelId 频道id 默认推荐页频道（未验证该tab是否支持指定频道）
   */
  static jump2TabFilmTV(channelId) {
    let params = {
      "biz_id": "100",
      "biz_plugin": "qiyibase",
      "biz_params": {
        "biz_sub_id": 1001,
        "biz_params": "selectedTab=" + (channelId || BridgeUtilPPS.CHANNEL_ID.RECOMMEND),
        "biz_dynamic_params": "",
        "biz_extend_params": "",
        "biz_statistics": ""
      }
    };
    this[jumpByRegistration](params);
  };

  /**
   * 跳转到首页 竖视频tab
   *
   */
  static jump2TabVerticalScreenVideo() {
    let params = {
      "biz_id": "100",
      "biz_plugin": "qiyibase",
      "biz_params": {
        "biz_sub_id": 114,
        "biz_params": "",
        "biz_dynamic_params": "",
        "biz_extend_params": "",
        "biz_statistics": ""
      }
    };
    this[jumpByRegistration](params);
  };

  /**
   * 跳转到首页 任务中心tabH5
   *
   */
  static jump2TabTaskCenterH5() {
    let params = {
      "biz_id": "100",
      "biz_plugin": "qiyibase",
      "biz_params": {
        "biz_sub_id": 1003,
        "biz_params": "",
        "biz_dynamic_params": "",
        "biz_extend_params": "",
        "biz_statistics": ""
      }
    };
    this[jumpByRegistration](params);
  };

  /**
   * 跳转到首页 用户中心（我的）tab
   *
   */
  static jump2TabUserCenter() {
    let params = {
      "biz_id": "100",
      "biz_plugin": "qiyibase",
      "biz_params": {
        "biz_sub_id": 424,
        "biz_params": "",
        "biz_dynamic_params": "",
        "biz_extend_params": "",
        "biz_statistics": ""
      }
    };
    this[jumpByRegistration](params);
  };

  /**
   * 跳转到首页 vip tab
   */
  static jump2TabVip() {
    let params = {
      "biz_id": 100,
      "biz_plugin": "qiyibase",
      "biz_params": {
        "biz_sub_id": 1005,
        "biz_params": "",
        "biz_dynamic_params": "",
        "biz_extend_params": "",
        "biz_statistics": ""
      }
    };
    this[jumpByRegistration](params);
  };

  /**
   * 跳转到半屏播放器
   * @param tvId
   * @param aId 专题id
   */
  static jump2HalfScreenPlayer(tvId, aId) {
    let params = {
      "biz_id": 102,
      "biz_plugin": "qiyibase",
      "biz_params": {
        "biz_sub_id": 101,
        "biz_params": "tvid=" + tvId + "&ctype=0&aid=" + aId + "&pc=0&ps=2",
        "biz_dynamic_params": "",
        "biz_extend_params": "from_type=27&from_sub_type=10",
        "biz_statistics": ""
      }
    };
    this[jumpByRegistration](params);
  };


  /**
   * 设置页-倒计时红包开关页
   */
  static jump2RedBag() {
    let params = {
      "biz_id": 100,
      "biz_plugin": "qiyibase",
      "biz_params": {
        "biz_sub_id": 1004,
        "biz_params": "",
        "biz_dynamic_params": "",
        "biz_extend_params": "",
        "biz_statistics": ""
      }
    };
    this[jumpByRegistration](params);
  };

  /**
   * 语音搜索入口
   */
  static jump2SearchOfVoice() {
    let params = {
      "biz_id": 100,
      "biz_plugin": "qiyibase",
      "biz_params": {
        "biz_sub_id": 1006,
        "biz_params": "",
        "biz_dynamic_params": "",
        "biz_extend_params": "",
        "biz_statistics": ""
      }
    };
    this[jumpByRegistration](params);
  };

  /**
   * 搜索二级页面
   */
  static jump2Search() {
    let params = {
      "biz_id": 100,
      "biz_plugin": "qiyibase",
      "biz_params": {
        "biz_sub_id": 1008,
        "biz_params": "",
        "biz_dynamic_params": "",
        "biz_extend_params": "",
        "biz_statistics": ""
      }
    };
    this[jumpByRegistration](params);
  };

  /**
   * 图文详情页
   */
  static jump2News(newsId) {
    let params = {
      "biz_id": 100,
      "biz_plugin": "qiyibase",
      "biz_params": {
        "biz_sub_id": 1009,
        "biz_params": "newsId=" + newsId,
        "biz_dynamic_params": "",
        "biz_extend_params": "",
        "biz_statistics": ""
      }
    };
    this[jumpByRegistration](params);
  };

  /**
   * 话题详情页
   */
  static jump2TopicDetail(id, {
    pingBack = '',
    dl_bkey = ''
  } = {}) {
    let biz_statisticsObj = {};
    pingBack ? biz_statisticsObj = {
      ...pingBack
    } : '';
    dl_bkey ? biz_statisticsObj.dl_bkey = dl_bkey : '';
    let params = {
      "biz_id": 112,
      "biz_plugin": "qiyibase",
      "biz_params": {
        "biz_sub_id": 6,
        "biz_params": "",
        "biz_dynamic_params": "id=" + id,
        "biz_extend_params": "",
        "biz_statistics": Utils.obj2QueryEmpty(biz_statisticsObj)
      }
    };
    this[jumpByRegistration](params);
  };


  /**
   * 零钱首页 包含开户和提现
   */
  static jump2QiYiWallt() {
    let params = {
      "biz_id": "104",
      "biz_plugin": "qiyiwallet",
      "biz_params": {
        "biz_sub_id": "2",
        "biz_params": "v_fc=PPS_activity",
        "biz_dynamic_params": "",
        "biz_extend_params": "",
        "biz_statistics": ""
      }
    };
    this[jumpByRegistration](params);
  };

  /**
   * 调起webview弹窗
   */
  static jump2WebViewDialog(url) {
    let params = {
      "biz_id": "100",
      "biz_plugin": "",
      "biz_params": {
        "biz_sub_id": "1020",
        "biz_params": "",
        "biz_dynamic_params": "url=" + encodeURIComponent(url),
        "biz_extend_params": "",
        "biz_statistics": ""
      }
    };
    this[jumpByRegistration](params);
  };

  /**
   * 榜单页面
   */
  static jump2RankList(ranklistId, issueId, categoryId, {
    pingBack = '',
    dl_bkey = ''
  } = {}) {
    let biz_statisticsObj = {};
    dl_bkey ? biz_statisticsObj.dl_bkey = dl_bkey : '';
    let params = {
      "biz_id": "100",
      "biz_plugin": "",
      "biz_params": {
        "biz_sub_id": "1032",
        "biz_params": 'ranklistId=' + ranklistId + '&issueId=' + issueId + '&categoryId=' + categoryId,
        "biz_dynamic_params": "",
        "biz_extend_params": "",
        "biz_statistics": Utils.obj2QueryEmpty(biz_statisticsObj)
      }
    };
    this[jumpByRegistration](params);
  };

  /**
   * 热门榜单
   */
  static jump2HotRankList(ranklistId, issueId, categoryId, {
    pingBack = '',
    dl_bkey = ''
  } = {}) {
    let biz_statisticsObj = {};
    dl_bkey ? biz_statisticsObj.dl_bkey = dl_bkey : '';
    let params = {
      "biz_id": "100",
      "biz_plugin": "",
      "biz_params": {
        "biz_sub_id": "1041",
        "biz_params": 'ranklistId=' + ranklistId + '&issueId=' + issueId + '&categoryId=' + categoryId,
        "biz_dynamic_params": "",
        "biz_extend_params": "",
        "biz_statistics": Utils.obj2QueryEmpty(biz_statisticsObj)
      }
    };
    this[jumpByRegistration](params);
  };
  /**
   * 频道页面
   * channelname :频道名字
   * channelID:可不传
   * beehiveTagId:频道id
   */
  static jump2Channel({
    content_tag,
    tagId = -1,
    beehiveTagId
  }, {
    pingBack = '',
    dl_bkey = ''
  } = {}) {
    let biz_statisticsObj = {};
    dl_bkey ? biz_statisticsObj.dl_bkey = dl_bkey : '';
    let params = {
      "biz_id": "100",
      "biz_params": {
        "biz_sub_id": "1034",
        "biz_params": "",
        "biz_dynamic_params": `target_tab=100&channelname=${content_tag}&channelID=${tagId}&top_feed=&beehiveTagId=${beehiveTagId}&aliasName=`,
        "biz_extend_params": "",
        "biz_statistics": Utils.obj2QueryEmpty(biz_statisticsObj)
      }
    }
    this[jumpByRegistration](params);
  };

  /**
   * 频道视频聚合页-话题tab
   */
  static jump2ChannelTopic({
    content_tag,
    tagId = -1,
    beehiveTagId
  }, {
    pingBack = '',
    dl_bkey = ''
  } = {}) {
    let biz_statisticsObj = {};
    dl_bkey ? biz_statisticsObj.dl_bkey = dl_bkey : '';
    let params = {
      "biz_id": "100",
      "biz_params": {
        "biz_sub_id": "1034",
        "biz_params": "",
        "biz_dynamic_params": `target_tab=200&channelname=${content_tag}&channelID=${tagId}&top_feed=&beehiveTagId=${beehiveTagId}&aliasName=`,
        "biz_extend_params": "",
        "biz_statistics": Utils.obj2QueryEmpty(biz_statisticsObj)
      }
    }
    this[jumpByRegistration](params);
  };

  /**
   * 个人空间页
   */
  static jump2Zone(uid) {
    let params = {
      "biz_id": "113",
      "biz_plugin": "qiyibase",
      "biz_params": {
        "biz_sub_id": "1",
        "biz_params": "",
        "biz_dynamic_params": "uid=" + uid,
        "biz_extend_params": "",
        "biz_statistics": ''
      }
    }
    this[jumpByRegistration](params);
  };
  /**
   * 跳转小程序页面
   */
  static jump2WeaPage(params) {
    // let params = {
    //   "biz_id": "311",
    //   "biz_plugin": "qiyibase",
    //   "biz_params": {
    //     "biz_sub_id": "901",
    //     "biz_params": "SWANUrl=iqiyi://swan/KcCOORnuCS6zFNQPIfGi7MLlXnkpXfmc",
    //     "biz_dynamic_params": "",
    //     "biz_extend_params": "",
    //     "biz_statistics": ""
    //   }
    // }
    console.log(params,'params')
    this[jumpByRegistration](params);
  }

  /**
   * pps站外h5拉起调用
   */
  static jump2CommonMethod(params, otherParams = {}) {
    console.log(params, 'params')
    let biz_statisticsObj = {};
    params.dl_bkey ? biz_statisticsObj.dl_bkey = params.dl_bkey : '';
    params.query.biz_statistics = Utils.obj2QueryEmpty(biz_statisticsObj);
    this[jumpByRegistration](params.query, otherParams);
  };

}

module.exports = BridgeUtilPPS;
