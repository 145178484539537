const Application = require('./Application')
const Utils = require('./Utils')

const s2 = Symbol("s2");
const sendPingBack = Symbol("sendPingBack");
const sendVVLogPingBack = Symbol("sendVVLogPingBack");
const devPingBack = Symbol("devPingBack");

class PingBack {
  static BASEURL = {
    RELEASE: '//msg.qy.net/v5/alt/act',//产品需求需要投递的基地址
    VIDEOBASEURL: '//msg.qy.net/b',//vvlog
    DEV: '//toutiao.iqiyi.com/pps/h5/v1/qos/fe.gif'//开发内部看的投递地址Grafana
  };
  static S2 = {
    WECHAT: 'wechat',
    WEIBO: 'weibo',
    IQIYI: 'iqiyi',
    QIYINEWS: 'qiyinews',
    BROWSER: 'browser'
  };
  static PINGBACKTYPE = {
    CLICK: 20,
    BLOCK: 21,//区域展示
    LOAD: 22,
    LEAVE: 30,//页面关闭
    VIDEOPLAY: 15,//开播
    VIDEOTIMING: 2//计时
  };


  [sendPingBack](t, rpage, block, rseat, extendsObj) {
    let baseOpt = {
      t: t,
      rseat: rseat,
      block: block,
      p1: "", //平台代码 ios 2_22_241 android 2_22_242 h5 2_20_120 //pps ios 202_22_221 安卓 202_22_222
      rpage: rpage,
      u: Application.appInfo.qyID || Utils.getPageUUID(),
      pu: Application.appInfo.ppuid || '',
      rn: new Date().getTime(), //随机码
      stime: new Date().getTime(),//系统时间
      s2: Utils.getUrlParam('s2') || this[s2] || '',
      dfp: Application.appInfo.dfp || '',

      s3: '',
      s4: '',
      v: Application.appInfo.appVersion || '',
      de: '',
      position: '',

      hu: '',
      ce: '',
      r: '',
      mkey: '',
      ntwk: Application.appInfo.networkStatus || '',//navigator.connection.effectiveType
      model: Application.os.ios ? 'ios' : 'android',
      osv: Application.os.ios ? 'iPhone' : 'Android',
      rootpage: '',
      contid: '',
      mod: 'cn_s',//地区语言模式，数据统计
      ftype: '',//原纳逗from_subtype 好多视频播放入口定义http://wiki.qiyi.domain/pages/viewpage.action?pageId=242945102
      source: Utils.getUrlParam('source') || '',//abTest 实验名称
      batch: Utils.getUrlParam('batch') || '',//abTest 实验分组
      abtest: Utils.getUrlParam('batch') || '',//abTest 实验分组
    };
    if (Application.browser.qiyinews) {
      //无法区分站外h5平台来源需警惕
      if (Application.os.ios) {
        baseOpt.p1 = '2_22_241';
      } else {
        baseOpt.p1 = '2_22_242';
      }
    } else if (Application.browser.pps || Application.browser.iqiyiAPP) {
      if (Application.os.ios) {
        baseOpt.p1 = '202_22_221';
      } else {
        baseOpt.p1 = '202_22_222';
      }
    } else {
      // baseOpt.p1 = '202_20_201';
      if (rpage === 'ciyuanvote_out') {
        baseOpt.p1 = '202_22_201'
      } else if (Application.os.ios) {
        baseOpt.p1 = '202_22_221';
      } else {
        baseOpt.p1 = '202_22_222';
      } 
    }
    if (extendsObj) {
      baseOpt = {...baseOpt, ...extendsObj}
    }
    /**
     * http://wiki.qiyi.domain/pages/viewpage.action?pageId=267223447
     *用户点击内广广告后，H5跳转链接里加上“广告创意id”和“广告展现端id”两个字段
     *H5根据透传字段，在H5页面展现，点击时增发上报该字段
     */
    Utils.getUrlParam('p1') && (baseOpt.adplt = Utils.getUrlParam('p1'));
    Utils.getUrlParam('creativeid') && (baseOpt.adcrid = Utils.getUrlParam('creativeid'));

    let image = new Image();
    image.src = PingBack.BASEURL.RELEASE + '?' + Utils.obj2QueryEmpty(baseOpt);
  }

  [sendVVLogPingBack](t, rpage, block, rseat, extendsObj, sendURL) {
    let baseOpt = {
      t: t,
      rseat: rseat,
      block: block,
      p1: "", //平台代码 ios 2_22_241 android 2_22_242 h5 2_20_120
      rpage: rpage,
      u: Application.appInfo.deviceId || '',
      pu: Application.appInfo.ppuid || '',
      rn: new Date().getTime(), //随机码
      stime: new Date().getTime(),//系统时间
      s2: Utils.getUrlParam('s2') || this[s2],
      dfp: Application.appInfo.dfp || '',

      s3: '',
      s4: '',
      v: Application.appInfo.appVersion || '',
      de: '',
      position: '',

      hu: '',
      ce: '',
      r: '',
      mkey: '',
      ntwk: Application.appInfo.networkStatus || '',//navigator.connection.effectiveType
      model: Application.os.ios ? 'ios' : 'android',
      rootpage: '',
      contid: '',
      ftype: ''//原纳逗from_subtype 好多视频播放入口定义http://wiki.qiyi.domain/pages/viewpage.action?pageId=242945102
    };
    if (Application.browser.qiyinews) {
      //无法区分站外h5平台来源需警惕
      if (Application.os.ios) {
        baseOpt.p1 = '2_22_241';
      } else {
        baseOpt.p1 = '2_22_242';
      }
    } else {
      baseOpt.p1 = '2_20_120';
    }
    if (extendsObj) {
      baseOpt = {...baseOpt, ...extendsObj}
    }
    let image = new Image();
    if(sendURL){//参数不为空向老地址投递，新的正在搭建中要兼容老的
      new Image().src = sendURL + '?' + Utils.obj2QueryEmpty(baseOpt);
    }
    image.src = PingBack.BASEURL.VIDEOBASEURL + '?' + Utils.obj2QueryEmpty(baseOpt);
  }

  [devPingBack](pageName, params) {
    let platform = '';
    if (Application.os.ios) {
      platform = 'ios'
    } else {
      platform = 'android'
    }
    new Image().src = PingBack.BASEURL.DEV + "?debug=1&app_key=h5" + "&hx_" + pageName + '=1' + "&hx_" + platform + '_' + pageName + '=1' + (params ? ('&' + Utils.obj2Query(params)) : '');
  }


  instance = null;
  [s2];

  constructor() {
    if (Application.browser.weixin) {
      this[s2] = PingBack.S2.WECHAT;
    } else if (Application.browser.weibo) {
      this[s2] = PingBack.S2.WEIBO;
    } else if (Application.browser.iqiyiAPP) {
      this[s2] = PingBack.S2.IQIYI;
    } else if (Application.browser.qiyinews) {
      this[s2] = PingBack.S2.QIYINEWS;
    } else {
      this[s2] = PingBack.S2.BROWSER;
    }
    if(location.protocol!='http:' && location.protocol!='https:'){
      for(let key in PingBack.BASEURL){
        PingBack.BASEURL[key] = 'http:'+PingBack.BASEURL[key]
      }
    }
  }

  setS3(s3) {
    this.s3 = s3;
  }

  getS2() {
    return s2;
  }

  sendClickPingBack(rpage, block, rseat, extendsObj) {
    this[sendPingBack](PingBack.PINGBACKTYPE.CLICK, rpage, block, rseat, extendsObj)
  }

  sendLoadPingBack(rpage, block, rseat, extendsObj) {
    this[sendPingBack](PingBack.PINGBACKTYPE.LOAD, rpage, block, rseat, extendsObj)
  }

  sendLeavePingBack(rpage, block, rseat, extendsObj) {
    this[sendPingBack](PingBack.PINGBACKTYPE.LEAVE, rpage, block, rseat, extendsObj)
  }

  sendBlockPingBack(rpage, block, rseat, extendsObj) {
    this[sendPingBack](PingBack.PINGBACKTYPE.BLOCK, rpage, block, rseat, extendsObj)
  }

  sendDirectivesBlockPingBack=({rpage, block, rseat, extendsObj}={})=> {
    this[sendPingBack](PingBack.PINGBACKTYPE.BLOCK, rpage, block, rseat, extendsObj)
  }

  sendVideoPlayPingBack(rpage, extendsObj, sendURL) {
    this[sendVVLogPingBack](PingBack.PINGBACKTYPE.VIDEOPLAY, rpage, null, null, extendsObj, sendURL)
  }

  sendVideoTimingPingBack(rpage, extendsObj) {
    this[sendVVLogPingBack](PingBack.PINGBACKTYPE.VIDEOTIMING, rpage, null, null, extendsObj)
  }

  sendDevPingBack(pageName, params) {
    this[devPingBack](pageName, params)
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new PingBack();
    }
    return this.instance;
  }
}

module.exports = PingBack;
