<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import Utils from "@/common/Utils";
 export default {
    mounted() {
      // https://vuejs.org/guide/scaling-up/ssr.html#component-lifecycle-hooks
     // For browser-only APIs, the common approach is to lazily access them inside client-only lifecycle hooks such as mounted.
      if (Utils.isMobileDevice()) {
        Utils.redirect("https://m.suike.cn/n/msite")
      }
    },
 }
</script>

<style>
  html,body,#app{
    font-size: 62.5%;
    margin:0;
    padding: 0;
    user-select: none;
    width:100%;
    height: 100%;
  }
  a{
    text-decoration: none;
  }
  img{border:0 none;}
  a:hover,a:visited,a:link,a:active{
    color:#000;
  }
  #app{
      min-height: 100%;
  }
</style>