const Application = require('./Application') 
const Clipboard =require('./Clipboard')
const Utils =require("./Utils")

const callSchemaByALink = Symbol('callSchemaByALink');
const callSchemaByLocation = Symbol('callSchemaByLocation');
const callSchemaByFrame = Symbol('callSchemaByFrame');

const showCoverImg = Symbol('showCoverImg');


const addOpenAppListener = Symbol('addOpenAppListener');


class AppCaller {

  static [callSchemaByALink](schemeUrl) {
    let body = document.body;
    let aLink = document.createElement('a');
    aLink.style.cssText = 'display:none;width:0px;height:0px;';
    aLink.href = schemeUrl;
    body.appendChild(aLink);
    aLink.click();
  };

  static [callSchemaByLocation](schemeUrl) {
    window.location.href = schemeUrl;
  };

  static [callSchemaByFrame](schemeUrl) {
    let iframe = document.createElement('iframe');
    iframe.style.cssText = 'display:none;width:0px;height:0px;';
    let body = document.body;
    body.appendChild(iframe);
    iframe.src = schemeUrl;
  };

  static [addOpenAppListener](TIMEOUT = 5000, callBack) {
    let start = Date.now();
    let loadTimer = setTimeout(function () {
      if (document.hidden || document.webkitHidden) {
        return;
      }
      // 如果app启动，浏览器最小化进入后台，则计时器存在推迟或者变慢的问题
      // 那么代码执行到此处时，时间间隔必然大于设置的定时时间
      if (Date.now() - start > TIMEOUT + 200) {
        // come back from app
        console.log('打开app成功');
        callBack && callBack();
        // 如果浏览器未因为app启动进入后台，则定时器会准时执行，故应该跳转到下载页
      } else {
        console.log('打开app失败，进入回调页面');
        if (!callBack || (callBack && !callBack())) {
          //无callBack or callBack 返回false 执行默认操作下载操作
          if (Application.os.ios) {
            console.log('ios打开失败执行默认下载');
            window.location.href = 'https://itunes.apple.com/cn/app/id445375097';
          } else {
            console.log('android打开失败执行默认下载');
            window.location.href = 'http://mbdapp.iqiyi.com/j/ps/pps_12505.apk';
          }
        }
      }
    }, TIMEOUT);
    // 当本地app被唤起，则页面会隐藏掉，就会触发pagehide与visibilitychange事件
    // 在部分浏览器中可行，网上提供方案，作hack处理
    let visibilitychange = function () {
      let tag = document.hidden || document.webkitHidden;
      console.log(tag, 'tag++++++++++');
      tag && clearTimeout(loadTimer);
    };
    document.addEventListener('visibilitychange', visibilitychange, false);
    document.addEventListener('webkitvisibilitychange', visibilitychange, false);
    // pagehide 必须绑定到window
    window.addEventListener('pagehide', function () {
      clearTimeout(loadTimer);
    }, false);
  }


  /**
   * 微博内唤起禁止，展示打开浏览器浮层
   */
  static [showCoverImg]() {
    let template = '<div id="_weibo_guide_mask" style="background-color: rgba(0,0,0,0.7);position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9999;">' +
      '<div style="position: absolute;top: .55rem;right: .27rem;">' +
      '<div style="display: flex;align-items: center;display: -webkit-flex;-webkit-align-items: center;">' +
      '<div style="font-size: .15rem;color: #ccc;height: .3rem;line-height: .3rem;">请点击右上角</div>' +
      '<div style="margin-left: .05rem;width: .32rem;height: .2rem;border-radius: .2rem;background: url(//m.iqiyipic.com/common/20190801/point.png) no-repeat;background-size: 130% 130%;background-position: center center;" ></div>' +
      '</div>' +
      '<div style="font-size: .15rem;color: #ccc;height: .3rem;line-height: .3rem;">选择在浏览器中打开此页面</div>' +
      '<div style="font-size: .15rem;color: #ccc;height: .3rem;line-height: .3rem;">为你下载或打开爱奇艺随刻版</div>' +
      '</div>' +
      '<img style="position: absolute;top: .2rem;right: .27rem;width: .5rem;height: .5rem;" src="//m.iqiyipic.com/common/20190801/arrow.png" alt="">' +
      '</div>'
    let container=document.getElementById('_weibo_guide_mask');
    if(!container){
      container = document.createElement("div");
      container.id='_weibo_guide_mask';
      container.innerHTML=template;
      container.onclick=function (e) {
        e.stopPropagation();
        e.preventDefault();
        container.style.display = 'none';
      };
      document.body.appendChild(container);
    }else {
      container.style.display='block';
    }
  }

  static call(schemeUrl, {callBack, timeOut} = {}) {
    let self = this;
    new Clipboard(schemeUrl, 'copy', () => {

    }).start();
    if (Application.browser.weibo) {
      this[showCoverImg]();
      return;
    }
    setTimeout(() => {
      self[addOpenAppListener](timeOut, callBack);
    }, 1);
    // 微信通过应用宝下载地址唤起
    // ios 9 safari 不支持iframe的方式跳转
    // app内部强制使用frame跳转，防止native未中断跳转造成的问题
    if (Application.os.android && Application.browser.weixin) {
      window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=tv.pps.mobile';
      return;
    }
    if (Application.os.ios && (Application.browser.weixin || Application.browser.qq || Application.browser.alipay)) {
      window.location.href = 'https://skh5.iqiyi.com/wechat.html?startFrom=wx&ftype=27&pluginParams=' + Utils.getParamInTheUrl(schemeUrl, 'pluginParams');
      return;
    }
    if (Application.os.ios) {
      if (Application.os.version >= 9) {
        //isIosEgt9 /iPhone OS (\d+)/i.exec(_ua) && /iPhone OS (\d+)/i.exec(_ua)[1] >= 9;
        console.log('[callOrDownloadApp] env : isIosEgt9');
        this[callSchemaByLocation](schemeUrl);
      } else {
        console.log('[callOrDownloadApp] env : isIoslt9');
        this[callSchemaByALink](schemeUrl);
      }
      return;
    }

    // android chrome 不支持iframe的方式跳转
    if (Application.os.android && Application.browser.chrome && !Application.browser.qq) {
      //(ua.match(/Chrome\/([\d.]+)/) || ua.match(/CriOS\/([\d.]+)/)) && isAndroid() && !isMobileQQ() && !isBrowserQQ();
      console.log('[callOrDownloadApp] env : isAndroidChrome');
      this[callSchemaByALink](schemeUrl);
      return;
    }
    // 其他浏览器
    // 适用：UC,sogou,firefox,mobileQQ
    console.log('[callOrDownloadApp] env : isOtherBrowser');
    this[callSchemaByFrame](schemeUrl);
    return;
  }
}

module.exports = AppCaller;
