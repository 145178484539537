const channels = {
    "首页":{
      id:-1,
      eName:''
    },
    "电影":{
      id:1,
      eName:'dianying'
    } ,
    "电视剧":{
      id:2,
      eName:'dianshiju'
    },
    "少儿": {
      id:15,
      eName:'shaoer'
    },
    "动漫": {
      id:4,
      eName:'dongman'
    },
    "综艺": {
      id:6,
      eName:'zongyi'
    },
    "游戏": {
      id:8,
      eName:'youxi'
    },
    "音乐": {
      id:5,
      eName:'yinyue'
    },
    "搞笑": {
      id:22,
      eName:'gaoxiao'
    },
    "军事": {
      id:28,
      eName:'junshi'
    },
    "娱乐": {
      id:7,
      eName:'yule'
    },
    "旅游": {
      id:9,
      eName:'lvyou'
    },
    "资讯": {
      id:25,
      eName:'zixun'
    },
    "母婴": {
      id:29,
      eName:'muying'
    },
    "汽车": {
      id:26,
      eName:'qiche'
    },
    "体育": {
      id:17,
      eName:'tiyu'
    }
  }
  export default channels;
  