import {addClickEvent, addScrollEvent} from './globalEvent'
import {isRootElement, showInScreen} from './element'

export class ClickObserver {
  num = 0

  constructor (callback, options = {}) {
    this.callback = callback || (() => {})
    this.idKey = options.key || '_clickId'
    this.optionsKey = `${this.idKey}Options`
    this.init()
  }

  init () {
    addClickEvent(event => {
      let element = event.target
      if (isRootElement(element)) return
      while (!element[this.idKey]) {
        element = element.parentNode
        if (!element || isRootElement(element)) return
      }
      this.callback(element, element[this.optionsKey], event)
    })
  }

  observe (el, options) {
    el[this.idKey] = this.num = this.num + 1
    el[this.optionsKey] = options
  }

  unobserve (el) {
    delete el[this.idKey]
    delete el[this.optionsKey]
  }
}

export class InterObserver {
  num = 0

  constructor (callback, options = {}) {
    this.callback = callback || (() => {})
    this.idKey = options.key || '_interId'
    this.optionsKey = `${this.idKey}Options`
    this.showKey = `${this.idKey}Show`
    this.isObserver = typeof IntersectionObserver !== 'undefined'
    this.els = []
    this.intersectionObserver = null
    this.init()
  }

  init () {
    if (this.isObserver) {
      this.intersectionObserver = new IntersectionObserver(entrys => {
        entrys.forEach((entry) => {
          if (entry.isIntersecting && !entry.target[this.showKey]) {
            const el = entry.target
            el[this.showKey] = true
            this.callback(el, el[this.optionsKey])
            this.unobserve(el)
          }
        })
      })
    } else {
      this._notify = () => {
        const els = [...this.els]
        els.forEach((el) => {
          if (showInScreen(el) && !el[this.showKey]) {

            el[this.showKey] = true
            this.callback(el, el[this.optionsKey])
            this.unobserve(el)
          }
        })
      }

      addScrollEvent(this._notify)
    }
  }

  observe (el, options) {
    el[this.idKey] = this.num = this.num + 1
    el[this.optionsKey] = options
    el[this.showKey] = false
    if (this.isObserver) {
      this.intersectionObserver.observe(el)
    } else {
      this.els.push(el)
    }
  }

  unobserve (el) {
    delete el[this.idKey]
    delete el[this.optionsKey]
    delete el[this.showKey]
    try {
      if (this.isObserver) {
        this.intersectionObserver.unobserve(el)
      } else {
        const index = this.els.indexOf(el)
        if (index > -1) this.els.splice(index, 1)
      }
    } catch (e) {}
  }

  notify () {
    if (this._notify) {
      this._notify()
    }
  }
}
