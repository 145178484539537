import 'babel-polyfill';
import promise from 'es6-promise'
promise.polyfill()
import Vue from 'vue'
import App from './App.vue'
import { createRouter } from './router'
import { createStore } from './store'
import { sync } from 'vuex-router-sync'
import { formatNum } from "@/common/num";
// import showPingback from '@/common/directives/showPingback'
// Vue.directive('show-pingback', showPingback) // block
Vue.prototype.formatNum = formatNum;
Vue.config.productionTip = false
export function createApp(){
  const router = createRouter();
  const store = createStore();
  sync(store, router)
  const app = new Vue({
    router,
    store,
    render: h => h(App)
  })
  
  return {app,store, router}
}

// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app')
