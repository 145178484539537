const Application = {
  baseApiUrl: process.env.NODE_ENV == 'production' ? ('https://toutiao.iqiyi.com') : 'http://10.16.171.78:8085',
  // browser: {
  //   qq: /qqbrowser|QQ|QQbrowser/.test(navigator.userAgent),
  //   weixin: /MicroMessenger/i.test(navigator.userAgent),
  //   uc: /ucbrowser|ucweb/i.test(navigator.userAgent),
  //   baidubox: /baiduboxapp/i.test(navigator.userAgent),
  //   baidubrowser: /baidubrowser/i.test(navigator.userAgent),
  //   weibo: /weibo/i.test(navigator.userAgent),
  //   ios_egt_9: /iPhone OS (\d+)/i.exec(navigator.userAgent) && /iPhone OS (\d+)/i.exec(navigator.userAgent)[1] >= 9,
  //   ios_egt_10: /iPhone OS (\d+)/i.exec(navigator.userAgent) && /iPhone OS (\d+)/i.exec(navigator.userAgent)[1] > 9,
  //   qiyinews: /QYNews/.test(navigator.userAgent) && !(/IqiyiApp\/pps/.test(navigator.userAgent)), //爱奇艺头条webview //兼容pps图文详情页打开后两个ua头都有的情况 9.8.5后修复
  //   pps: /IqiyiApp\/pps/.test(navigator.userAgent), //PPSwebview
  //   iqiyiAPP: /IqiyiApp\/iqiyi/.test(navigator.userAgent), //只用来判断是否为爱奇艺系列app内(含头条)
  //   alipay: /Alipay/i.test(navigator.userAgent),
  // }
}
export default Application;