import {
  InterObserver
} from '../service/observer'
import isString from 'lodash/isString'
import PingBack from "@/common/PingBack";
const KEY_BLOCK = "block";
const interObserver = new InterObserver((el, options = {}) => {
  const block = options.block
  const _sendBlock = options.callback || PingBack.getInstance().sendDirectivesBlockPingBack
  _sendBlock(options)
})
export default {
  bind(el, binding) {
    if (!binding.value.rpage) return;
    const blockValue = binding.value
    const options = isString(binding.value) ? {
      block: blockValue
    } : blockValue || {}
    el[KEY_BLOCK] = options.block
    interObserver.observe(el, options)
  },


  inserted() {
    interObserver.notify()
  },

  unbind(el) {
    interObserver.unobserve(el)
    delete el[KEY_BLOCK]
  }
}
