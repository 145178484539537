import throttle from 'lodash/throttle'

let scrollCallbacks = []
let callbacks = []
let orientationCallbacks = []

let SCROLL_WAIT = 100


window.document.addEventListener('click', event => {
  callbacks.forEach(callback => {
    callback(event)
  })
})

export function addClickEvent (callback) {
  callbacks.push(callback)
}

export function removeClickEvent (callback) {
  const index = callbacks.indexOf(callback)
  if (index > -1) {
    callbacks.splice(index, 1)
  }
}

export function clearClickEvent () {
  callbacks = []
}

if (typeof window !== 'undefined') {
  window.addEventListener('orientationchange' in window ? 'orientationchange' : 'resize', throttle(event => {
    event.portrait = window.orientation === 180 || window.orientation === 0
    event.landscape = window.orientation === 90 || window.orientation === -90
    orientationCallbacks.forEach(callback => {
      try {
        callback(event)
      } catch (err) {}
    })
  }))
}

export function addOrientationEvent (callback) {
  orientationCallbacks.push(callback)
}

export function removeOrientationEvent (callback) {
  const index = orientationCallbacks.indexOf(callback)
  if (index > -1) {
    orientationCallbacks.splice(index, 1)
  }
}

window.document.addEventListener('scroll', throttle(event => {
  event.scrollDirection = scrollFunc().scrollDirection
  event.scrollOffset = scrollFunc().scrollOffset
  scrollCallbacks.forEach(callback => {
    try {
      callback(event)
    } catch (err) {}
  })
}, SCROLL_WAIT))

export function addScrollEvent (callback) {
  scrollCallbacks.push(callback)
}

export function removeScrollEvent (callback) {
  const index = scrollCallbacks.indexOf(callback)
  if (index > -1) {
    scrollCallbacks.splice(index, 1)
  }
}

export function clearScrollEvent () {
  scrollCallbacks = []
}

let scrollOffset = { x: 'undefined', y: 'undefined' }
let scrollDirection = ''

function scrollFunc () {
  if (typeof scrollOffset.x === 'undefined') {
    scrollOffset.x = window.pageXOffset
    scrollOffset.y = window.pageYOffset
  }
  const diffX = scrollOffset.x - window.pageXOffset
  const diffY = scrollOffset.y - window.pageYOffset
  if (diffX < 0) {
    scrollDirection = 'right'
  } else if (diffX > 0) {
    scrollDirection = 'left'
  } else if (diffY + 20 < 0) {
    scrollDirection = 'down'
  } else if (diffY - 20 > 0) {
    scrollDirection = 'up'
  }
  scrollOffset.x = window.pageXOffset
  scrollOffset.y = window.pageYOffset
  return {
    scrollDirection,
    scrollOffset
  }
}
