<template>
<div>
    <div class="mask" v-if="showMask">
        <div v-if="showContactUs" class="contact-content">
            <img class="cancel" src="../imgs/cancel.png" @click="hideMask"/>
            <div class="title">联系我们</div>
            <div class="introduce">随刻APP是爱奇艺全新推出的一个精彩、多元化的视频社区产品，旨在为用户提供丰富视频、创作分享、社区互动体验。随时随刻精彩一下！</div>
            <div class="contact-ways">
                <div class="contact-way">
                    <div class="sub-title"><img src="../imgs/address.png" class="sub-logo"/><span class="title-words">联系地址</span></div>
                     <div class="sub-content">
                         <div>北京市朝阳区工人体育场北路21号</div>
                         <div>永利国际购物中心4层</div>
                     </div>
                </div>
                <div class="contact-way">
                      <div class="sub-title"><img src="../imgs/email.png" class="sub-logo"/><span class="title-words">联系邮箱</span></div>
                      <div class="sub-content">
                         <div>爱奇艺号：iqiyihao@qiyi.com</div>
                         <div>MCN合作：iqiyihao_mcn@qiyi.com</div>
                      </div>
                </div>
            </div>
             <div class="contact-ways">
                <div class="contact-way">
                      <div class="sub-title"><img src="../imgs/wechat.png" class="sub-logo"/><span class="title-words">微信公众号</span></div>
                       <div class="sub-content">
                             <img src="//static-s.iqiyi.com/skhome/20200701/微信公众号.png" class="wechat_img">
                       </div>
                </div>
                <div class="contact-way">
                      <div class="sub-title"><img src="../imgs/weibo.png" class="sub-logo"/><span class="title-words">官方微博</span></div>
                      <div class="sub-content">
                          <img src="//static-s.iqiyi.com/skhome/20200701/官方微博.png" class="webo_img">
                      </div>
                </div>
            </div>
        </div>
    </div>
    <div class="top-bgd">
    <div class="top-container">
       <img src="../imgs/logo.png" class="logo"/>
       <div class="tab-content">
           <div class="tab-box" v-for="(item,index) in tabs" :key="item">
               <div class="tab-parent">
                 <div class="tab"  @click="tabClick(index)" @mouseover="showQRCode(index)" @mouseleave="hideQRCode(index)">
                       {{item}}
                </div>
                </div>
                   <div v-show="(index===0&&showDownloadCode)" class="QRCode-content">
                       <img src="../imgs/qrcode.png" class="QRCode"/>
                       <div class="QRCode-msg">扫码下载随刻APP</div>
                       <div class="QRCode-msg">随时随刻，精彩一下</div>
                   </div>
            </div>
       </div>
    </div>
    </div>
</div>
</template>

<script>
// import PingBack from "@/common/PingBack"
    export default {
        data(){
            return {
                tabs:[
                    "下载App",
                    "创作者平台",
                    "追光计划",
                    "联系我们"
                ],
                showDownloadCode:false,
                showMask:false,
                showContactUs:false,
                rpage:'pcw_home'
            }
        },
        components:{
        },
        created(){
        },
        beforeMount(){
            PingBack.getInstance().sendBlockPingBack('pcw_home','top_tab')
        },
        methods:{
            tabClick(index){
                let self=this;
                switch (index) {
                    case 0:

                        break;

                    case 1:
                        window.open("https://mp.iqiyi.com")
                        PingBack.getInstance().sendClickPingBack(this.rpage,'top_tab','creator')
                        break;
                    case 2:
                        window.open("https://mp.iqiyi.com/supportNew/home")
                        PingBack.getInstance().sendClickPingBack(this.rpage,'top_tab','spotlight')
                        break;
                    case 3:
                        self.showMask=true;
                        self.showContactUs=true;
                        PingBack.getInstance().sendBlockPingBack('pcw_home','contact')
                        break;
                }
            },
            showQRCode(index){
                if(index==0){
                    this.showDownloadCode=true
                    PingBack.getInstance().sendBlockPingBack('pcw_home','download')
                }

            },
            hideQRCode(index){
                if(index==0){
                    this.showDownloadCode=false
                }
            },
            hideMask(){
                this.showMask=false
                this.showContactUs=false
            }
        }
    }
</script>

<style lang="scss" scoped>
     .mask{
        position: fixed;
        width:100%;
        height: 100%;
        top:0;
        left:0;
        right: 0;
        bottom:0;
        z-index: 10;
        background: rgba(0,0,0,0.4);
        .contact-content{
            position: absolute;
            cursor: pointer;
            left:50%;
            top:50%;
            transform: translate(-50%,-50%);
            width: 525px;
            height: 384px;
            padding:24px;
            box-sizing: border-box;
            z-index: 11;
            background: #FFFFFF;
            border-radius: 16px;
              .cancel{
                position: absolute;
                right: 16px;
                top: 16px;
                width: 16px;
                height: 16px;
            }
            .title{
                font-family: PingFangSC-Semibold;
                font-size: 18px;
                color: #1F2229;
                letter-spacing: 0;
                text-align: center;
                font-weight: 600;
                margin-bottom: 12px;
            }
            .introduce{
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #1F2229;
                letter-spacing: 0;
                text-align: justify;
                font-weight: 400;
                margin-bottom: 24px;
            }
            .contact-ways{
                clear:both;
                .contact-way{
                    width:50%;
                    float:left;
                    .sub-title{
                        margin-bottom: 7px;
                        .sub-logo{
                            width:20px;
                            height: 20px;
                            vertical-align: middle;
                            margin-right: 8px;
                        }
                        .title-words{
                            font-family: PingFangSC-Medium;
                            display: inline-block;
                            vertical-align: middle;
                            font-size: 14px;
                            color: #1F2229;
                            letter-spacing: 0;
                            text-align: justify;
                            font-weight: 500;
                        }
                    }
                    .sub-content{
                        height: 80px;
                        line-height: 20px;
                        margin-left: 28px;
                        font-family: PingFangSC-Light;
                        font-size: 12px;
                        color: #1F2229;
                        letter-spacing: 0;
                        font-weight: 200;
                        .wechat_img,.webo_img{
                            width:80px;
                            height: 80px;
                            margin-left: -4px;
                        }
                    }
                }
            }
        }
    }
    .top-bgd{
         width:100%;
        background: #FFFFFF;
    .top-container{
        width:100%;
        max-width: 1968px;
        height: 56px;
        margin:0 auto;
        box-shadow: inset 0 -1px 0 0 #EDEEF0;
        padding-left:40px;
        box-sizing: border-box;
        .logo{
            width: 74px;
            height: 48px;
            vertical-align: middle;
        }
        .tab-content{
            margin-left:140px;
             width:calc(100% - 254px);
             display: inline-block;
             vertical-align: middle;
            .tab-box{
                position: relative;
                display: inline-block;
                vertical-align: middle;
                margin: 0 12px;

                .tab-parent{
                    overflow: hidden;
                    border-radius: 16px;
                .tab{
                    padding:6px 16px;
                    color: #1F2229;
                    font-size: 14px;
                    display: inline-block;
                    &:hover{
                        opacity: 0.8;
                        cursor:pointer;
                        color:#fff;
                        border-radius: 16px;
                        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#FF325F, endColorstr=#FA1E2D);/*IE<9>*/
-ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#FF325F, endColorstr=#FA1E2D)";/*IE8+*/
                        background-image: linear-gradient(225deg, #FF325F 0%, #FA1E2D 100%);
                    }
                }
                }
                .QRCode-content{
                    position: absolute;
                    z-index: 2;
                    width:216px;
                    height: 246px;
                    margin-top:16px;
                    left: 0;
                    box-sizing: border-box;
                    border-radius: 10px;
                    background: #FFFFFF;
                    border: 1px solid #EDEEF0;
                    box-shadow: 0 2px 8px 0 rgba(31,34,41,0.10);
                    .QRCode{
                        width:124px;
                        height: 124px;
                        margin:34px auto 16px;
                        display: block;
                    }
                    .QRCode-msg{
                        font-family: PingFangSC-Semibold;
                        font-size: 14px;
                        color: #1F2229;
                        letter-spacing: 0;
                        text-align: center;
                        font-weight: 600;
                    }
                }
            }

        }
    }
    }
</style>
