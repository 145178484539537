export function formatNum (num, defaultNum = 0) {
  if (!num || num <= 0 || isNaN(num)) return defaultNum.toString()

  if (num < 10000) {
    return num.toString()
  } else if (num < 100000000) {
    return (Math.floor(num / 1e3) / 10) + '万'
  } else {
    return (Math.floor(num / 1e7) / 10) + '亿'
  }
}

export function billionLimit (num, defaultNum = 0) {
  if (!num || num <= 0 || isNaN(num)) return defaultNum

  if (num < 10000) {
    return num
  } else if (num <= 9990000) {
    return (num / 10000).toFixed(1) + 'w'
  } else {
    return '999w+'
  }
}

export function chinaNum (num, defaultNum = 0) {
  if (!num || num <= 0 || isNaN(num)) return defaultNum

  if (num < 100000) {
    return addSeparator(num, ',', 3)
  } else if (num < 1000000) {
    return (num / 10000).toFixed(1) + '万'
  } else if (num < 100000000) {
    return (num / 10000).toFixed(0) + '万'
  } else {
    return (num / 100000000).toFixed(1) + '亿'
  }
}

// 添加分割符
function addSeparator (num, sep, len) {
  var numStr = num.toString()
  var result = ''
  for (var i = numStr.length; i > 0; i = i - len) {
    var start = (i - len) < 0 ? 0 : (i - len)
    result = sep + numStr.slice(start, i) + result
  }
  return result.substring(1)
}
