// import {
//   Toast
// } from '../views'
// import md5 from './Md5'
// import Cookies from './Cookies'
const md5 = require("./Md5")
const Cookies = require("./Cookies")

const SECURITY_KEY = 'w0JD89dhtS7BdPLU2'
const Utils =  {
  // showToast(options, callback) {
  //   Toast(options, callback);
  // },
  /**
   * 比较版本号
   * @param v1
   * @param v2
   * @returns
   */
  compareVersion(version1, version2) {
    var v1 = version1.split('.');
    var v2 = version2.split('.');
    var a = v1.length - v2.length;
    if (a !== 0) {
      //兼容 compareVersion('9.11', '9.11.2')
      for (var j = 0; j < Math.abs(a); j++) {
        if (a > 0) {
          v2.push(0);
        } else {
          v1.push(0);
        }
      }
    }
    for (var i = 0; i < v1.length; i++) {
      if (v1[i] - v2[i] > 0) {
        return 1;
      } else if (v1[i] - v2[i] < 0) {
        return -1;
      }
    }
    return 0;
  },
  /**
   * @desc 函数节流
   * @param func 函数
   * @param wait 延迟执行毫秒数
   * @param type 1 表时间戳版，2 表定时器版
   */
  throttle(method, context) {
    clearTimeout(method.tId)
    method.tId = setTimeout(function () {
      method.call(context);
    }, 300);
    console.log(method.tId, 'method')
  },
  debounce(fn, delay = 300) { //默认300毫秒
    var timer;
    return function () {
      var args = arguments;
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        fn.apply(this, args); // this 指向vue
      }, delay);
    };
  },
  getUrlParam(param) {
    var reg = new RegExp("(^|&)" + param + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return r[2];
    return null;
  },
  getParamInTheUrl(url, param) {
    let reg = new RegExp("(^|&)" + param + "=([^&]*)(&|$)", "i");
    if (url.indexOf('?') > -1) {
      let search = url.substring(url.indexOf('?'))
      var r = search.substr(1).match(reg);
      if (r != null) return r[2];
    } else {
      return null;
    }
    return null;
  },
  obj2Query(params) {
    var count = 0;
    var query = '';
    for (var key in params) {
      if (Object.prototype.hasOwnProperty.call(params,key)) {
        var param = params[key];
        if (param !== false && param !== 0 && !param) {
          continue;
        } else {
          if (++count == 1) {
            query = key + "=" + encodeURIComponent(param);
          } else {
            query += "&" + key + "=" + encodeURIComponent(param);
          }
        }

      }
    }
    return query;
  },
  obj2QueryEmpty(params) {
    var count = 0;
    var query = '';
    for (var key in params) {
      if (Object.prototype.hasOwnProperty.call(params,key)) {
        var param = params[key];
        if (param !== false && param !== 0 && !param) {
          param = ''
          if (++count == 1) {
            query = key + "=" + encodeURIComponent(param);
          } else {
            query += "&" + key + "=" + encodeURIComponent(param);
          }
          continue;
        } else {
          if (++count == 1) {
            query = key + "=" + encodeURIComponent(param);
          } else {
            query += "&" + key + "=" + encodeURIComponent(param);
          }
        }

      }
    }
    return query;
  },
  loadScript(url, callback) {
    if (document.getElementById(url)) {
      callback && callback();
    } else {
      let head = document.head || document.getElementsByTagName('head')[0] || document.documentElement,
        script;
      script = document.createElement('script');
      script.id = url;
      script.async = false;
      script.type = 'text/javascript';
      script.src = url;
      console.log('加载的url=' + url);
      head.insertBefore(script, head.firstChild);
      if (callback) {
        if (document.addEventListener) {
          script.addEventListener('load', callback, false);
          console.log('插入script成功')
        } else {
          script.onreadystatechange = function () {
            if (/loaded|complete/.test(script.readyState)) {
              script.onreadystatechange = null;
              callback()
            }
          }
        }
      }
    }

  },
  /**
   * 着急上线，临时放置于此
   * @returns
   */
  getPageUUID() {
    let uid = Cookies.get('QC006');
    if (!uid) {
      uid = md5(window.navigator.userAgent + document.cookie + Math.random() + new Date().getTime());
      Cookies.set('QC006', uid, {
        expires: 365,
        path: '/',
        domain: document.domain
      })
    }
    return uid;
  },
  ppuid2Format(ppuid) {
    if (ppuid !== 0 && ppuid && ppuid != '' && ppuid != 'null' && ppuid != '0') {
      return ppuid;
    } else {
      return null;
    }
  },
  /**
   * var str1 = stringFormat.format("我叫{0},性别{1}","美男子", "男"); //log 我叫美男子,性别男
   * var user = {name: "美男子",sex: "男",age: 20};
   * stringFormat("我叫{name},性别{sex},今年{age}岁",user); //我叫美男子,性别男,今年20岁
   * @param str
   * @returns {*}
   */
  stringFormat(str) {
    let param = arguments[1];
    var s = str;
    if (typeof(param) == 'object') {
      for (let key in param)
        s = s.replace(new RegExp("\\{" + key + "\\}", "g"), param[key]);
      return s;
    } else {
      for (let i = 0; i < arguments.length; i++)
        s = s.replace(new RegExp("\\{" + i + "\\}", "g"), arguments[i+1]);
      return s;
    }
  },
  
  /**
   * 获取营销域名基路径
   */
  getMarketUrl(){
    // if(process.env.NODE_ENV == 'production'){
    //   //*.c0o0l.com  *.s0k0y.com 保留
    //   let hosts = ['.cxg201.com/','.d4h2p.com/','.k66xw.com/'];
    //   let dirs = ['market','ma','mb','mc','md','me','mf','mg','mh','mi','mj'];

    //   let hostRandomPosition=Math.ceil(Math.random()*hosts.length)-1;
    //   let dirRandomPosition=Math.ceil(Math.random()*dirs.length)-1;
    //   return 'http://'+Math.random().toString(36).slice(-8) + hosts[hostRandomPosition] + dirs[dirRandomPosition];
    // }else {
    //   return 'http://nadouh5.iqiyi.com/nadou';
    // }
    return 'http://m.suike.cn';
  },

  /**
   * 是否为Mobile
   */
  isMobileDevice(){
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(window.navigator.userAgent);
  },

  /**
   * redirect
   */
  redirect(url) {
    window.location.href = url;
  }
}
module.exports = Utils;
