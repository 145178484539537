<template>
    <div class="shortVideo" id="shortVideo">
    <template v-if="videoLists.length"  v-show-pingback>
        <div class="video-content" v-for="(item,index) in videoLists" :key="'video'+index" @click="goVideo(item)">
            <a :href="item.playUrl" class="seo-a" @click.prevent>
            <div class="image-box">
                <img :src="item.image" class="video-image"/>
                <span class="episode">{{s_to_ms(item.duration)}}</span>
            </div>
            <div class="video-msg">
                <img :src="item.authorImageUrl||defaultAvatar" class="video-avatar">
                <div class="video-box">
                    <div class="video-title">{{item.title}}</div>
                    <div class="introduce-msg"><span class="author">{{item.nickName||'随刻视频用户'}}</span></div>
                </div>
            </div>
            </a>
        </div>
    </template>
          <div class="tip-box">
              <div class="loading-box">
                 <div ref="lottie" class="sk-loading" v-show="loading"></div>
              </div>
            <div v-show="!loading&&noData" class="noData">暂无更多数据</div>
          </div>
    </div>
</template>

<script>
// import Api from "@/common/ApiV2";
import Channels from "../channel"
// import PingBack from "@/common/PingBack"
import lottie from 'lottie-web';
import animationData from "../imgs/loading.json";
    export default {
        props: {
            rpage: {
            type: String,
            default: ""
            }
        },
        data(){
            return {
                // videoLists:[],
                pageSize:30,
                noData:false,
                loading:false,
                concatBanner:false,
                scrollLoading:false,
                timeoutCount:0,
                defaultAvatar:'https://www.iqiyipic.com/common/fix/headicons/male-130.png'
            }
        },
        computed: {
            videoLists(){
                return this.$store.state.dataList || [];
            },
            from(){
                return this.$store.state.from||0
            },
            channelId(){
                return this.$store.state.channelId||-1
            }
        },
       beforeMount(){
            // console.log(this.$route)
            let name=this.$route.name
            this.channelId=Channels[name].id
            // console.log(this.channelId)
            let _uaDetect = navigator.userAgent;
            this.platform = _uaDetect.match(/(Android);?[\s\/]+([\d.]+)?/)? "ANDROID_PPS": "IOS_PPS";
            // this.getVideoList(Channels[name].id)
            PingBack.getInstance().sendLoadPingBack(this.rpage);
            //主页pingback的rpage为pcw_home
            if(this.channelId==-1){
                PingBack.getInstance().sendBlockPingBack('pcw_home','pcw_feed')
            }
            else{
                PingBack.getInstance().sendBlockPingBack('pcw_home_'+Channels[name].eName,'pcw_feed')
            }

        },
        mounted(){
            this.$nextTick(()=>{
                console.log('refs',this.$refs.lottie)
                lottie.loadAnimation({
                    container: this.$refs.lottie, // the dom element that will contain the animation
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    animationData: animationData // the path to the animation json
                });
                window.addEventListener("scroll", this.handleScroll,true);
            })
        },
        destroyed() {
            console.log("destroyed销毁");
            window.removeEventListener("scroll", this.handleScroll,true);
            this.bscroll = null;
        },
        methods:{
            handleScroll() {
                let targetElement=document.querySelector('.shortVideo')
                if(targetElement)
                {
                    var scrollHeight =targetElement.scrollHeight;
                    var scrollTop =targetElement.scrollTop;
                    var clientHeight = targetElement.clientHeight;
                    if (scrollTop + clientHeight >= scrollHeight - 120 && !this.scrollLoading) {
                        this.scrollLoading = true;
                        // this.from += this.pageSize;
                        this.getVideoList(this.channelI);
                        console.log("已到底");
                    }
                }
            },
            getVideoList(){
                let self=this
                self.loading=true
                lottie.play()
                this.$store.dispatch("FETCH_NEXT_PAGE", {
                channelId: this.channelId,
                platform: self.platform||'IOS_PPS',
                from: this.from+this.pageSize,
                size: this.pageSize,
                "source":"new_request"
                }).then(data=>{
                    if(data.data)
                    {
                        self.timeoutCount=0
                        data=data.data
                        self.loading=false
                        lottie.stop()
                        self.scrollLoading = false;
                        //  let data=res.data
                        //一般无bannerList
                        //  if(data.bannerList&&data.bannerList.length>0&&!self.concatBanner){
                        //     self.concatBanner=true
                        //     self.videoLists=self.videoLists.concat(data.bannerList)
                        // }
                        if(data.list)
                        {
                            self.from = data.from;
                            if (!data.nextHaveData){
                                console.log('剩余的不够了')
                                window.removeEventListener("scroll", this.handleScroll,true);
                                self.noData=true
                            }
                            self.videoLists=self.videoLists.concat(data.list)

                        }
                    }
                    else if (data.code == "E00001") {
                    console.log('超时')
                    //超时出现3次就停止请求，直接返回无数据
                    if(self.timeoutCount>2){
                        self.loading=false
                        self.noData=true
                        window.removeEventListener("scroll", this.handleScroll);
                    }
                    else{
                        self.timeoutCount++;
                        self.getVideoList();
                    }

                }
                else{
                    self.loading=false
                    self.noData=true
                    window.removeEventListener("scroll", this.handleScroll);
                }
                }).catch(err => {
                    lottie.stop()
                    console.log('超时',err)
                if (err.code == "E00001") {
                    //超出两次timeout不继续请求，直接无数据
                    if(self.timeoutCount>2){
                        self.loading=false
                        self.noData=true
                        window.removeEventListener("scroll", this.handleScroll);
                    }
                    else{
                        self.timeoutCount++;
                        self.getVideoList();
                    }
                }
                else{
                    self.loading=false
                    self.noData=true
                    window.removeEventListener("scroll", this.handleScroll,true);
                }
                console.log("err", err);
                });
            },
            goVideo(video){
                let name=this.$route.name
                //主页pingback的rpage为pcw_home
                if(this.channelId==-1){
                    PingBack.getInstance().sendClickPingBack('pcw_home','pcw_feed','conten',{'contid':video.id})
                }
                else{
                    PingBack.getInstance().sendClickPingBack('pcw_home_'+Channels[name].eName,'pcw_feed','conten',{'contid':video.id})
                }

                window.open(video.playUrl)
            },
            s_to_ms(s) {
            var m = Math.floor(s / 60);
            s = s % 60;
            m += "";
            s += "";
            m = m.length == 1 ? "0" + m : m;
            s = s.length == 1 ? "0" + s : s;
            return m + ":" + s;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .shortVideo{
        float: left;
        width:calc(100% - 270px);
        padding:24px;
        height: 100%;
        // box-sizing: border-box;
        overflow-y: auto;
        .video-content{
            // width:32%;
            float:left;
            &:hover{
                opacity: 0.8;
            }
            .seo-a{
                display: block;
            }
            // &:nth-of-type(3n-1){
            //     margin:0 2%;
            // }
            .image-box{
                width:100%;
                position: relative;
                height: 0;
                padding-bottom: 56.25%;
                background:#eee;
                overflow: hidden;
                .episode{
                    position: absolute;
                    right: 10px;
                    bottom:10px;
                    color:#FFFFFF;
                    font-family: PingFangSC-Regular;
                    font-size: 14px;
                }
                .video-image{
                    width:100%;
                    height: auto;
                }
            }
            .video-msg{
                // display: flex;
                padding:12px 0 24px 0 ;
                overflow: hidden;
                .video-avatar{
                    float: left;
                    width:40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 1px solid #EDEEF0;
                    margin-right: 12px;
                    // flex-shrink: 0;
                }
                .video-box{
                    float: left;
                    width:calc(100% - 54px);
                .video-title{
                    opacity: 0.8;
                    font-family: PingFangSC-Semibold;
                    font-size: 14px;
                    color: #1F2229;
                    word-wrap: break-word;
                    letter-spacing: 0;
                    line-height: 22px;
                    font-weight: 600;
                }
                .introduce-msg{
                    font-family: PingFangSC-Regular;
                    font-size: 12px;
                    color: #999DA6;
                    letter-spacing: 0;
                    line-height: 12px;
                    font-weight: 400;
                    margin-top: 12px;
                    .author{
                        margin-right: 9px;
                    }
                    .playCount{
                        margin-left: 9px;
                    }
                }
                }
            }
        }
        .tip-box{
            width:100%;
            clear: both;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            .loading-box{
                width:100%;
                .sk-loading{
                    text-align: center;
                    width:112px;
                    position: relative;
                    margin:30px auto;
                    overflow:hidden;
                }
            }
            .noData{
                width:112px;
                text-align: center;
                font-size:14px;
                margin:20px auto;
            }
        }
    }
    @media(max-width: 1160px){
        .shortVideo{
            .video-content{
                width:32%;
                &:nth-of-type(3n-1){
                    margin:0 2%;
                }
                &:nth-of-type(3n-2){
                    clear:both;
                }
            }
        }
    }
     @media (min-width: 1161px) and (max-width: 1967px){
        .shortVideo{
            .video-content{
                width:24%;
                margin-right:1.3%;
                &:nth-of-type(4n){
                    margin-right: 0;
                }
                &:nth-of-type(4n-3){
                    clear:both;
                }
            }
        }
    }
      @media (min-width: 1968px){
        .shortVideo{
            .video-content{
                width:19%;
                margin-right:1.2%;
                &:nth-of-type(5n){
                    margin-right: 0;
                }
                &:nth-of-type(5n-4){
                    clear:both;
                }
            }
        }
    }
</style>
