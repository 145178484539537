import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../pages/index/main/index.vue'

import ShortVideo from '../pages/index/components/shortvideo'
import LongVideo from '../pages/index/components/longvideo'
import Channels from '../pages/index/channel'

const createListView = (channelId,rpage,component) => () => import('../pages/index/components/CreateListView').then(m => m.default(channelId,rpage,component))
Vue.use(VueRouter)

const routes=[{
    path: '/pcw/',
    component: Index,
    props: (route) => ({rpage:"pcw_home"}),
    children: [{
        path: '',
        name: '首页',
        component: createListView(Channels['首页'].id,'pcw_home',ShortVideo),
        props: (route) => ({rpage:"pcw_home"}),
        meta: {
            title: "爱奇艺随刻-海量视频社区-内容创作分享"
        },
        },
        {
        path: 'game',
        name: '游戏',
        component: createListView(Channels['游戏'].id,`pcw_category.${Channels['游戏'].id}`,ShortVideo),
        props: (route) => ({rpage:`pcw_category.${Channels['游戏'].id}`}),
        meta: {
            title: "游戏-爱奇艺随刻-海量视频社区-内容创作分享"
        },
        },
        {
        path: 'music',
        name: '音乐',
        component:createListView(Channels['音乐'].id,`pcw_category.${Channels['音乐'].id}`,ShortVideo),
        props: (route) => ({rpage:`pcw_category.${Channels['音乐'].id}`}),
        meta: {
            title: "音乐-爱奇艺随刻-海量视频社区-内容创作分享"
        },
        },
        {
        path: 'gaoxiao',
        name: '搞笑',
        component:createListView(Channels['搞笑'].id,`pcw_category.${Channels['搞笑'].id}`,ShortVideo),
        props: (route) => ({rpage:`pcw_category.${Channels['搞笑'].id}`}),
        meta: {
            title: "搞笑-爱奇艺随刻-海量视频社区-内容创作分享"
        },
        },
        {
        path: 'junshi',
        name: '军事',
        component:createListView(Channels['军事'].id,`pcw_category.${Channels['军事'].id}`,ShortVideo),
        props: (route) => ({rpage:`pcw_category.${Channels['军事'].id}`}),
        meta: {
            title: "军事-爱奇艺随刻-海量视频社区-内容创作分享"
        },
        },
        {
        path: 'yule',
        name: '娱乐',
        component:createListView(Channels['娱乐'].id,`pcw_category.${Channels['娱乐'].id}`,ShortVideo),
        props: (route) => ({rpage:`pcw_category.${Channels['娱乐'].id}`}),
        meta: {
            title: "娱乐-爱奇艺随刻-海量视频社区-内容创作分享"
        },
        },
        {
        path: 'lvyou',
        name: '旅游',
        component:createListView(Channels['旅游'].id,`pcw_category.${Channels['旅游'].id}`,ShortVideo),
        props: (route) => ({rpage:`pcw_category.${Channels['旅游'].id}`}),
        meta: {
            title: "旅游-爱奇艺随刻-海量视频社区-内容创作分享"
        },
        },
        {
        path: 'zixun',
        name: '资讯',
        component:createListView(Channels['资讯'].id,`pcw_category.${Channels['资讯'].id}`,ShortVideo),
        props: (route) => ({rpage:`pcw_category.${Channels['资讯'].id}`}),
        meta: {
            title: "资讯-爱奇艺随刻-海量视频社区-内容创作分享"
        },
        },
        {
        path: 'muying',
        name: '母婴',
        component:createListView(Channels['母婴'].id,`pcw_category.${Channels['母婴'].id}`,ShortVideo),
        props: (route) => ({rpage:`pcw_category.${Channels['母婴'].id}`}),
        meta: {
            title: "母婴-爱奇艺随刻-海量视频社区-内容创作分享"
        },
        },
        {
        path: 'car',
        name: '汽车',
        component:createListView(Channels['汽车'].id,`pcw_category.${Channels['汽车'].id}`,ShortVideo),
        props: (route) => ({rpage:`pcw_category.${Channels['汽车'].id}`}),
        meta: {
            title: "汽车-爱奇艺随刻-海量视频社区-内容创作分享"
        },
        },
        {
        path: 'tiyu',
        name: '体育',
        component:createListView(Channels['体育'].id,`pcw_category.${Channels['体育'].id}`,ShortVideo),
        props: (route) => ({rpage:`pcw_category.${Channels['体育'].id}`}),
        meta: {
            title: "体育-爱奇艺随刻-海量视频社区-内容创作分享"
        },
        },
        {
        path: 'video',
        name: '电影',
        component: createListView(Channels['电影'].id,`pcw_category.${Channels['电影'].id}`,LongVideo),
        props: (route) => ({rpage:`pcw_category.${Channels['电影'].id}`}),
        meta: {
            title: "电影-爱奇艺随刻-海量视频社区-内容创作分享"
        },
        },
        {
        path: 'tv',
        name: '电视剧',
        component:createListView(Channels['电视剧'].id,`pcw_category.${Channels['电视剧'].id}`,LongVideo),
        props: (route) => ({rpage:`pcw_category.${Channels['电视剧'].id}`}),
        meta: {
            title: "电视剧-爱奇艺随刻-海量视频社区-内容创作分享"
        },
        },
        {
        path: 'zongyi',
        name: '综艺',
        component: createListView(Channels['综艺'].id,`pcw_category.${Channels['综艺'].id}`,LongVideo),
        props: (route) => ({rpage:`pcw_category.${Channels['综艺'].id}`}),
        meta: {
            title: "综艺-爱奇艺随刻-海量视频社区-内容创作分享"
        },
        },
        {
        path: 'child',
        name: '少儿',
        component: createListView(Channels['少儿'].id,`pcw_category.${Channels['少儿'].id}`,LongVideo),
        props: (route) => ({rpage:`pcw_category.${Channels['少儿'].id}`}),
        meta: {
            title: "少儿-爱奇艺随刻-海量视频社区-内容创作分享"
        },
        },
        {
        path: 'dongman',
        name: '动漫',
        component: createListView(Channels['动漫'].id,`pcw_category.${Channels['动漫'].id}`,LongVideo),
        props: (route) => ({rpage:`pcw_category.${Channels['动漫'].id}`}),
        meta: {
            title: "动漫-爱奇艺随刻-海量视频社区-内容创作分享"
        },
        },
    ]
    }]

export function createRouter() {
    return new VueRouter({
        fallback:false,
        mode: 'history',
        scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
        },
        base:'/',
        routes
   })
}
// router.beforeEach((to, from, next) => {
//     if (to.meta.title) {//如果设置标题，拦截后设置标题
//       document.title = to.meta.title
//     }
//     next()
//   })
//   export default router;